import styled from '@emotion/styled';

export const FilterDropdown = styled.div`
  top: 40px;
  right: 0px;
  width: 100%;
  z-index: 3;
  position: absolute;
  background-color: #fff;
  display: flex;
  align-items: stretch;
  -ms-box-orient: vertical;
  box-orient: vertical;
  flex-direction: column;
  border-radius: var(--lp-space-xxxs);
  box-shadow: var(--lp-shadows-medium);

  &.w-328 {
    min-width: 328px;
    max-width: 328px;
  }
  &.w-449 {
    min-width: 449px;
    max-width: 449px;

    @media (max-width: 767px) {
      min-width: 328px;
      max-width: 328px;
    }
  }
  &.w-656 {
    min-width: 656px;
    max-width: 656px;

    @media (max-width: 767px) {
      min-width: 328px;
      max-width: 328px;
    }
  }

  .filter-body {
    flex-grow: 1;
    padding: 0 24px;
    -ms-box-orient: vertical;
    box-orient: vertical;
    flex-direction: column;

    & > fieldset {
      margin: 0 -24px;
      width: calc(100% + 48px);

      .checkbox-items {
        width: 100%;
        display: flex;
        -ms-box-orient: vertical;
        box-orient: vertical;
        flex-direction: column;
        padding: var(--lp-space-sm);
        border-bottom: 1px solid #f0f2f9;

        &:first-of-type,
        &:only-child {
          padding-top: 5px;
        }

        &:last-of-type,
        &:last-of-type,
        &:only-child {
          border-bottom: 0 none;
        }

        & > label {
          display: flex;
          align-items: center;
          width: 100%;
          margin: 0px !important;
        }

        &-details {
          display: flex;
          -ms-box-orient: vertical;
          box-orient: vertical;
          flex-direction: column;
          padding-top: var(--lp-space-sm);

          .w-24 {
            width: 24px;
            max-width: 24px;
          }
          .w-24,
          .w-40,
          .w-60px,
          .w-120px {
            flex-shrink: 0;
          }
          .w-40 {
            width: 40px;
            max-width: 40px;
            margin: 0 var(--lp-space-xs);
          }
          .w-60px {
            width: 62px;
            max-width: 62px;
            margin: 0 var(--lp-space-xs);
          }
          .w-140px {
            margin: 0;
            width: 140px;
            max-width: 140px;
          }
        }
      }
    }
    span.Mui-Error {
      display: inline-block;
      padding-top: var(--lp-space-xxxs);
      color: rgb(var(--lp-colors-error-600));
    }
  }

  .filter-header,
  .filter-footer,
  .filter-body {
    flex-shrink: 0;
  }

  .filter-footer,
  .filter-header {
    color: #191e33;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .filter-header {
    margin-bottom: 24px !important;
    border-bottom: 1px solid #f0f2f9;
    padding: var(--lp-space-sm) var(--lp-space-sm) var(--lp-space-xs);

    h2 {
      color: #191e33;
    }

    .button-close {
      min-width: inherit;
      margin: 0;
      padding: 0;
      width: 24px;
      height: 24px;

      svg {
        fill: #191e33;
      }
    }

    &.mb-0 {
      margin-bottom: 19px !important;
    }
  }

  .filter-footer {
    border-top: 1px solid #f0f2f9;
    padding: var(--lp-space-sm);

    button {
      min-width: inherit;
      max-width: 70px;
      margin: 0 !important;
    }
  }
`;

export const SmallCheckboxes = styled.div`
  fieldset {
    overflow: inherit;

    label {
      margin: 0px !important;
      margin-bottom: 12px !important;

      input {
        width: 18px;
        height: 18px;

        &:focus {
          border-color: #b4b4b4;
          box-shadow: 0 0 0 4px rgba(59, 153, 252, 0.2);
        }

        &:checked {
          width: 18px;
          height: 18px;
          border-color: #3b99fc;
          background-color: #3b99fc;

          &:hover {
            border-color: #3b99fc;
          }

          &::after {
            width: 18px;
            height: 18px;
            top: -3.5px;
            left: -2.5px;
            content: ' ';
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.7071 9.70711C18.0976 9.31658 18.0976 8.68342 17.7071 8.29289C17.3166 7.90237 16.6834 7.90237 16.2929 8.29289L10 14.5858L7.70711 12.2929C7.31658 11.9024 6.68342 11.9024 6.29289 12.2929C5.90237 12.6834 5.90237 13.3166 6.29289 13.7071L9.29289 16.7071C9.68342 17.0976 10.3166 17.0976 10.7071 16.7071L17.7071 9.70711Z' fill='%23FFF'/%3E%3C/svg%3E%0A");
            background-size: 20px 20px !important;
          }
        }
      }

      .lp-text-neutral-600 {
        color: #011e25;
      }
    }
  }
`;
