import { getMerchantOrderDetail } from 'api/merchant/dashboard/orders';
import { OrderAPIResponseType } from 'api/merchant/dashboard/orders.types';
import { getMerchantPaymentDetail, getPaymentSourceVerification } from 'api/merchant/dashboard/payments';
import {
  PaymentAPIResponseType,
  PaymentSourceVerificationAPIResponseType,
} from 'api/merchant/dashboard/payments.types';
import MerchantDashboardLayout from 'layouts/merchant-dashboard/MerchantDashboardLayout';
import MerchantDefaultRoutes from 'pages/merchant/constants';
import qs from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/merchant/hooks';
import { getMerchantBaseUrl } from 'utils/getBaseUrl';
import getErrorMessage from 'utils/getErrorMessage';

import DashboardRoutesList from '../../constants';
import PaymentRoutesList from '../constants';
import PaymentDetails from './PaymentDetails';

function PaymentDetailPage() {
  const navigate = useNavigate();
  const { txnId } = qs.parse(window.location.search);

  // detail section variables
  const [showDetailLoader, setShowDetailLoader] = useState<boolean>(false);
  const [paymentDetailObj, setPaymentDetailObj] = useState<PaymentAPIResponseType | null>(null);
  const [paymentSourceVerificationObj, setPaymentSourceVerificationObj] =
    useState<PaymentSourceVerificationAPIResponseType | null>(null);
  const [orderDetailObj, setOrderDetailObj] = useState<OrderAPIResponseType | null>(null);
  const [detailErrorMsg, setDetailErrorMsg] = useState<string>('');

  const { apiBaseUri, merchantName, sourceVerificationEnabled } = useAppSelector((state) => ({
    apiBaseUri: state.config.apiBaseUri,
    merchantName: state.config.merchantName,
    sourceVerificationEnabled: state.config.sourceVerificationEnabled,
  }));

  const detailSectionBackClickhandler = () => {
    navigate(
      `${getMerchantBaseUrl(merchantName)}/${MerchantDefaultRoutes.DASHBOARD_ROUTE}/${
        DashboardRoutesList.PAYMENT_ROUTE
      }/${PaymentRoutesList.PAYMENT_LANDING}`,
    );
  };

  const fetchPaymentDetail = useCallback(async () => {
    try {
      if (typeof txnId !== 'string') return;

      setShowDetailLoader(true);

      const paymentDetailObj = await getMerchantPaymentDetail(txnId);
      const paymentSourceVerificationObj = await getPaymentSourceVerification(apiBaseUri, txnId).catch((e) => null);
      const orderDetailObj = await getMerchantOrderDetail(paymentDetailObj.purchaseDetails.orderId);

      paymentDetailObj.timelines?.reverse();

      setPaymentDetailObj(paymentDetailObj);
      setPaymentSourceVerificationObj(paymentSourceVerificationObj);
      setOrderDetailObj(orderDetailObj);
    } catch (error) {
      setDetailErrorMsg(getErrorMessage(error));
    } finally {
      setShowDetailLoader(false);
    }
  }, [apiBaseUri, txnId]);

  useEffect(() => {
    if (txnId) {
      fetchPaymentDetail();
    }
  }, [fetchPaymentDetail, txnId]);

  return (
    <MerchantDashboardLayout activeTab="payment" title="Payment Detail">
      <PaymentDetails
        transactionId={typeof txnId === 'string' ? txnId : null}
        onBackButtonClick={() => detailSectionBackClickhandler()}
        reloadPaymentDetails={() => fetchPaymentDetail()}
        setPaymentSourceVerificationObj={setPaymentSourceVerificationObj}
        showDetailLoader={showDetailLoader}
        paymentDetailObj={paymentDetailObj}
        paymentSourceVerificationObj={paymentSourceVerificationObj}
        orderDetailObj={orderDetailObj}
        detailErrorMsg={detailErrorMsg}
        sourceVerificationEnabled={sourceVerificationEnabled}
      />
    </MerchantDashboardLayout>
  );
}

export default PaymentDetailPage;
