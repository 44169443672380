/** @jsxImportSource @emotion/react */

import { searchCustomersQueryDocument } from 'api/graphql';
import { useMemo, useState } from 'react';
import { useQuery } from 'urql';

import { SearchFilter, SearchFilterOption, SearchFilterProps } from './SearchFilter';

export type CustomerFilterProps = {
  merchantIds: string | string[];
  value: SearchFilterOption | null;
  onChange(value: SearchFilterOption | null): void;
};

export function CustomerFilter({ merchantIds, value, onChange, ...props }: CustomerFilterProps) {
  const [query, setQuery] = useState('');

  const pause = query.length < 3;

  const [{ fetching, data, error }] = useQuery({
    query: searchCustomersQueryDocument,
    variables: { merchantIds, query },
    pause,
  });

  const options = useMemo<SearchFilterProps['options']>(() => {
    if (pause || !data?.search.customers) return [];

    return data.search.customers.map(({ customerId, customerEmailAddress }) => ({
      value: customerId,
      label: customerEmailAddress,
    }));
  }, [data?.search.customers, pause]);

  return (
    <SearchFilter
      value={value}
      options={options}
      placeholder="Search customer"
      loading={fetching}
      error={error?.message}
      onChange={onChange}
      onInputChange={setQuery}
    />
  );
}
