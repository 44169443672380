import { payoutsQueryDocument } from 'api/graphql';
import { getMerchantBalances } from 'api/merchant/dashboard/payouts';
import { GetMerchantBalancesResponse } from 'api/merchant/dashboard/settlement.types';
import MerchantDashboardLayout from 'layouts/merchant-dashboard/MerchantDashboardLayout';
import ErrorComponent from 'pages/common/error/ErrorComponent';
import { toAmountFilterInput, toInstantFilterInput, toLocalDateFilterInput } from 'pages/common/filters';
import { LISTING_RECORDS_PER_PAGE } from 'pages/platform/constants';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SortOrder, TableColumn } from 'react-data-table-component';
import { useAppDispatch, useAppSelector } from 'redux/merchant/hooks';
import { setListingPagePropsObj } from 'redux/merchant/slice/payoutSlice';
import { useQuery } from 'urql';
import getErrorMessage from 'utils/getErrorMessage';

import DashboardRoutesList from '../../constants';
import PayoutBalancePage from './partials/PayoutBalancePage';
import { PayoutsQueryEdges } from './types';

function PayoutPage() {
  const dispatch = useAppDispatch();
  const [balancesError, setBalancesError] = useState<string>('');
  const [balance, setBalance] = useState<GetMerchantBalancesResponse>({
    currency: 'AUD',
    available: 0,
    inTransit: 0,
    pending: 0,
  });
  const [filterPopupOpen, setFilterPopupOpen] = useState<boolean>(false);

  const { merchantId, payoutFilterObj, listingPageProps } = useAppSelector((state) => ({
    merchantId: state.config.merchantId,
    payoutFilterObj: state.payout.payoutFilterObj,
    listingPageProps: state.payout.listingPageProps,
  }));

  const { first, last, before, after } = useMemo(() => listingPageProps, [listingPageProps]);

  const limit = useMemo(() => first ?? last ?? LISTING_RECORDS_PER_PAGE, [first, last]);

  const filters = useMemo(() => {
    const { amount, payoutId, createdOn, availableOn, status } = payoutFilterObj;

    return {
      amount: amount ? toAmountFilterInput(amount) : null,
      payoutIds: payoutId?.value,
      createdAt: createdOn ? toInstantFilterInput(createdOn) : null,
      settlementDate: availableOn ? toLocalDateFilterInput(availableOn) : null,
      status,
    };
  }, [payoutFilterObj]);

  const [{ fetching, data, error: payoutsError }] = useQuery({
    query: payoutsQueryDocument,
    variables: { last, before, first, after, ...filters },
  });

  const error = payoutsError?.message ?? balancesError;

  const handleSort = (selectedColumn: TableColumn<PayoutsQueryEdges[number]>, sortDirection: SortOrder) => {};

  const toggleFilter = useCallback(() => {
    setFilterPopupOpen((wasOpened) => !wasOpened);
  }, []);

  const handlePageChange = useCallback(
    (page: number) => {
      if (!data?.payouts) return;

      const isFirst = page === 1;
      const isPrev = !isFirst && page === listingPageProps.page - 1;
      const isNext = page === listingPageProps.page + 1;
      const isLast = !isNext && page === Math.ceil(data.payouts.totalCount / limit);

      dispatch(
        setListingPagePropsObj({
          ...listingPageProps,
          page,
          last: isLast || isPrev ? limit : null,
          before: !isLast && isPrev ? data.payouts.pageInfo.startCursor ?? null : null,
          first: isFirst || isNext ? limit : null,
          after: !isFirst && isNext ? data.payouts.pageInfo.endCursor ?? null : null,
        }),
      );
    },
    [data?.payouts, dispatch, limit, listingPageProps],
  );

  const handlePerRowsChange = useCallback(
    async (newPerPage: number, page: number) => {
      dispatch(
        setListingPagePropsObj({
          ...listingPageProps,
          page,
          last: listingPageProps.last !== null ? newPerPage : null,
          first: listingPageProps.first !== null ? newPerPage : null,
        }),
      );
    },
    [dispatch, listingPageProps],
  );

  const fetchMerchantBalances = useCallback(async () => {
    setBalancesError('');

    try {
      const balance = await getMerchantBalances(merchantId);
      setBalance(balance);
    } catch (error) {
      setBalancesError(getErrorMessage(error));
    }
  }, [merchantId]);

  useEffect(() => {
    fetchMerchantBalances();
  }, [fetchMerchantBalances]);

  return (
    <MerchantDashboardLayout activeTab={DashboardRoutesList.PAYOUT_ROUTE} title="Balances">
      {!!error && <ErrorComponent bodyText={error} />}
      <PayoutBalancePage
        balance={balance}
        payoutList={data?.payouts?.edges ?? []}
        fetchListLoader={fetching}
        totalRows={data?.payouts?.totalCount ?? 0}
        handlePerRowsChange={handlePerRowsChange}
        handlePageChange={handlePageChange}
        limit={limit}
        toggleFilter={toggleFilter}
        activePage={listingPageProps.page}
        handleSort={handleSort}
        filterPopupOpen={filterPopupOpen}
      />
    </MerchantDashboardLayout>
  );
}

export default PayoutPage;
