import { PayType, TransactionStatus } from 'api/graphql/generated/graphql';

import { StatusTagProps } from './listing/partials/StatusTagComponent';

export const PaymentRoutesList = {
  PAYMENT_LANDING: 'all',
  PAYMENT_DETAILS: 'details',
  PAYOUT_DETAILS: 'payout-details',
  PAYMENT_TRANSACTIONS: 'all-transactions',
};

interface IPaymentListStatusObj {
  [status: string]: {
    label: string;
    varient: StatusTagProps['variant'];
    tooltipText: string;
  };
}

export const PaymentListStatusObj: IPaymentListStatusObj = {
  paid: {
    label: 'Paid',
    varient: 'success',
    tooltipText: 'This payment is successful and funds have been collected.',
  },
  refunded: {
    label: 'Refunded',
    varient: 'neutral',
    tooltipText: 'A full refund has been initiated on this payment.',
  },
  partially_refunded: {
    label: 'Partial refund',
    varient: 'neutral',
    tooltipText: 'A partial refund of the original payment amount has been initiated on this payment.',
  },
  pending: {
    label: 'Pending',
    varient: 'positive',
    tooltipText: 'This payment is still pending.',
  },
  failed: {
    label: 'Failed',
    varient: 'negative',
    tooltipText: 'The attempt to collect funds from the payment method was not successful.',
  },
  cancelled: {
    label: 'Cancelled',
    varient: 'neutral',
    tooltipText: 'This payment has been cancelled.',
  },
  disputed: {
    label: 'Disputed',
    varient: 'warning',
    tooltipText: 'At least one charge on the payment method has been disputed.',
  },
  not_captured: {
    label: 'Authorised',
    varient: 'positive',
    tooltipText: 'The payment method has been authorised but funds are not yet captured.',
  },
  incomplete: {
    label: 'Incomplete',
    varient: 'negative',
    tooltipText: 'This payment has not been completed by the customer.',
  },
  accepted: {
    label: 'Accepted',
    varient: 'positive',
    tooltipText: 'Collecting funds will be attempted from the payment method.',
  },
};

export const toTransactionStatusObj = (status: TransactionStatus) =>
  PaymentListStatusObj[
    status === TransactionStatus.NotCaptured
      ? 'not_captured'
      : status === TransactionStatus.PartiallyRefunded
      ? 'partially_refunded'
      : status.toLowerCase()
  ] ?? {};

export const PaymentExportColumnsObj = {
  OrderedOnDate: 'Ordered On Date',
  CardNumber: 'Card Number',
  OrderId: 'Order Id',
  CardExpiryDate: 'Card Expiry Date',
  PaymentOption: 'Payment Option',
  IssuingBank: 'Issuing Bank',
  Amount: 'Amount',
  Currency: 'Currency',
  IssuingCountry: 'Issuing Country',
  CvvCheck: 'CVV Check',
  LatestUpdatedDate: 'Latest Updated Date',
  ThreeDS: '3DS',
  Status: 'Status',
  CardType: 'Card Type',
  StatusReason: 'Status Reason',
  RefundId: 'Refund Id',
  DisputeId: 'Dispute Id',
  DisputeFee: 'Dispute Fee',
  Merchant: 'Merchant',
  MerchantId: 'MerchantId',
  TransactionId: 'Transaction Id',
  OrderType: 'Order Type',
  Description: 'Description',
  CardHolderName: 'Card Holder Name',
  ServiceFeesTotal: 'Service Fees Total',
  ServiceFeesFixedPercentage: 'Service Fees Fixed Percentage',
  ServiceFeesFixedAmount: 'Service Fees Fixed Amount',
  MerchantSurchargeTotal: 'Merchant Surcharge Total',
  CustomerName: 'Customer Name',
  CustomerId: 'Customer Id',
  CustomerEmail: 'Customer Email',
  CustomerPhoneNumber: 'Customer Phone Number',
};

export const PaymentFilterStatusObj = {
  Paid: {
    label: 'Paid',
    value: TransactionStatus.Paid,
  },
  Refunded: {
    label: 'Refunded',
    value: TransactionStatus.Refunded,
  },
  PartiallyRefunded: {
    label: 'Partially Refunded',
    value: TransactionStatus.PartiallyRefunded,
  },
  Pending: {
    label: 'Pending',
    value: TransactionStatus.Pending,
  },
  Failed: {
    label: 'Failed',
    value: TransactionStatus.Failed,
  },
  Cancelled: {
    label: 'Cancelled',
    value: TransactionStatus.Cancelled,
  },
  Disputed: {
    label: 'Disputed',
    value: TransactionStatus.Disputed,
  },
  NotCaptured: {
    label: 'Not Captured',
    value: TransactionStatus.NotCaptured,
  },
  Incomplete: {
    label: 'Incomplete',
    value: TransactionStatus.Incomplete,
  },
  Accepted: {
    label: 'Accepted',
    value: TransactionStatus.Accepted,
  },
};

export const PaymentFilterPaymentOptionObj = {
  PayInFull: {
    label: 'Full payment',
    value: PayType.PayInFull,
  },
  PayPlan: {
    label: 'Payment plan',
    value: PayType.PayPlan,
  },
};

export const TxnExportColumnsObj = {
  CreatedOnDate: 'CreatedOnDate',
  TransactionId: 'TransactionId',
  TransactionType: 'TransactionType',
  Amount: 'Amount',
  Currency: 'Currency',
  Merchant: 'Merchant',
  MerchantId: 'MerchantId',
  OrderId: 'OrderId',
  AvailableOnDate: 'AvailableOnDate',
  PayoutId: 'PayoutId',
  TransactionTransferId: 'TransactionTransferId',
};

export const TxnFilterEntryTypeObj = {
  Payment: 'Payment',
  Refund: 'Refund',
  PaymentFee: 'PaymentFee',
  TransactionTransfer: 'TransactionTransfer',
  PaymentFeeReversal: 'PaymentFeeReversal',
  TransactionTransferReversal: 'TransactionTransferReversal',
  TransactionTransferFeeReversal: 'TransactionTransferFeeReversal',
  Payout: 'Payout',
  PayoutReversal: 'PayoutReversal',
  Dispute: 'Dispute',
  DisputeFee: 'DisputeFee',
  DisputeReversed: 'DisputeReversed',
  DisputeFeeReversed: 'DisputeFeeReversed',
  MarketplaceFeePayment: 'MarketplaceFeePayment',
};

export const TxnStatusObj: IPaymentListStatusObj = {
  paid: {
    label: 'Paid',
    varient: 'success',
    tooltipText: 'This payout is successful.',
  },
  canceled: {
    label: 'Cancelled',
    varient: 'neutral',
    tooltipText: 'Cancelled',
  },
  failed: {
    label: 'Failed',
    varient: 'negative',
    tooltipText: 'Failed',
  },
  pending: {
    label: 'Pending',
    varient: 'positive',
    tooltipText: 'Pending',
  },
  in_transit: {
    label: 'In Transit',
    varient: 'warning',
    tooltipText: 'In Transit',
  },
  reversed: {
    label: 'Reversed',
    varient: 'warning',
    tooltipText: 'Reversed',
  },
  suspended: {
    label: 'Suspended',
    varient: 'negative',
    tooltipText: 'Suspended',
  },
};

export const PaymentSourceVerificationStatus = {
  unverified: { label: 'Unverified', iconName: 'Error', color: 'rgb(var(--lp-colors-neutral-500))' },
  passed: { label: 'Passed', iconName: 'Tick', color: 'rgb(var(--lp-colors-success-600))' },
  failed: { label: 'Failed', iconName: 'Error', color: 'rgb(var(--lp-colors-error-600))' },
  expired: { label: 'Expired', iconName: 'Error', color: 'rgb(var(--lp-colors-persimmon-600))' },
};

export const DigitalWalletTypes = {
  'Apple Pay': { label: 'ApplePay\u2122' },
  'Google Pay': { label: 'GooglePay\u2122' },
};

export default PaymentRoutesList;
