import { TransactionOrderField } from 'api/graphql/generated/graphql';
import * as s from 'assets/styles/Platforms.styles';
import { DateTime } from 'luxon';
import MerchantDefaultRoutes from 'pages/merchant/constants';
import { TableStyleSettings, TableThemeSettings } from 'pages/platform/constants';
import DashboardRoutesList from 'pages/platform/dashboard/constants';
import DataTable, { SortOrder, TableColumn, createTheme } from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/merchant/hooks';
import { toCurrency } from 'utils/currency';
import { getMerchantBaseUrl } from 'utils/getBaseUrl';

import { Spinner } from '@limepayments/cosmic';

import PaymentRoutesList, { toTransactionStatusObj } from '../../constants';
import { TransactionsQueryEdges } from '../types';
import EmptyPaymentListComponent from './EmptyPaymentListComponent';
import FilterEmptyPaymentListComponent from './FilterEmptyPaymentListComponent';
import StatusTag from './StatusTagComponent';

const columns: TableColumn<TransactionsQueryEdges[number]>[] = [
  {
    name: 'Amount',
    selector: (row: TransactionsQueryEdges[number]) =>
      toCurrency(row.node.amount.minorCurrencyUnits, row.node.amount.currency),
    sortable: true,
    sortField: TransactionOrderField.Amount,
    width: '100px',
    right: true,
  },
  {
    name: '',
    selector: (row: TransactionsQueryEdges[number]) => row.node.amount.currency,
    width: '70px',
    style: {
      color: 'rgb(var(--lp-colors-neutral-400))',
    },
  },
  {
    name: 'Status',
    cell: (row: TransactionsQueryEdges[number]) => {
      const status = toTransactionStatusObj(row.node.status);
      return (
        <StatusTag
          label={status.label ?? ''}
          variant={status.varient ?? ''}
          tooltipDirection="right"
          tooltipText={status.tooltipText ?? ''}
          paymentTxnId={row.node.transactionId}
        />
      );
    },

    sortable: true,
    sortField: TransactionOrderField.Status,
    width: '120px',
  },
  {
    name: 'Customer',
    selector: (row: TransactionsQueryEdges[number]) => row.node.customer?.customerName ?? '',
  },
  {
    name: 'Payment option',
    selector: (row: TransactionsQueryEdges[number]) =>
      row.node.payType === 'PayInFull'
        ? 'Full payment'
        : row.node.payType === 'PayPlan'
        ? 'Payment plan'
        : row.node.payType,
    width: '160px',
  },
  {
    name: 'Order ID',
    selector: (row: TransactionsQueryEdges[number]) =>
      (row.node.order?.referenceOrderId || row.node.order?.orderId) ?? '',
    minWidth: '200px',
  },
  {
    name: 'Transaction ID',
    selector: (row: TransactionsQueryEdges[number]) => row.node.transactionId,
    minWidth: '200px',
  },
  {
    name: 'Ordered on',
    selector: (row: TransactionsQueryEdges[number]) =>
      row.node.createdAt ? `${DateTime.fromISO(row.node.createdAt).toFormat('dd/LL/yyyy, HH:mm')}` : '',
    sortable: true,
    sortField: TransactionOrderField.CreatedAt,
    minWidth: '130px',
  },
  {
    name: 'Available on',
    selector: (row: TransactionsQueryEdges[number]) =>
      row.node.availableOn ? `${DateTime.fromISO(row.node.availableOn).toFormat('dd/LL/yyyy, HH:mm')}` : ``,
    sortable: true,
    sortField: TransactionOrderField.AvailableOn,
    minWidth: '130px',
  },
];

// createTheme creates a new theme named solarized that overrides the build in dark theme
createTheme('polarized', TableThemeSettings, 'dark');

interface Props {
  paymentList: TransactionsQueryEdges;
  fetchListLoader: boolean;
  totalRows: number;
  handlePerRowsChange: (newPerPage: number, page: number) => void;
  handlePageChange: (page: number) => void;
  limit: number;
  activePage: number;
  isFilterRequest: boolean;
  handleSort: (selectedColumn: TableColumn<TransactionsQueryEdges[number]>, sortDirection: SortOrder) => void;
  toggleFilter: () => void;
}

function MerchantsDataTable({
  paymentList,
  totalRows,
  limit,
  fetchListLoader,
  handlePageChange,
  handlePerRowsChange,
  activePage,
  handleSort,
  isFilterRequest,
  toggleFilter,
}: Props) {
  const navigate = useNavigate();

  const { merchantName } = useAppSelector((state) => ({
    merchantName: state.config.merchantName,
  }));

  return (
    <s.MerchantPaymentsTable>
      <DataTable
        pagination
        responsive
        columns={columns}
        data={paymentList}
        customStyles={TableStyleSettings}
        theme="polarized"
        className=""
        progressPending={fetchListLoader}
        progressComponent={
          <div className="transaction-empty">
            <div className="transaction-empty-inner">
              <Spinner variant="simple" isVisible label="Loading..." />
            </div>
          </div>
        }
        noDataComponent={
          isFilterRequest && paymentList.length < 1 ? (
            <FilterEmptyPaymentListComponent toggleFilter={toggleFilter} />
          ) : (
            <EmptyPaymentListComponent
              title={'No payments to display'}
              body={'Payments will appear here when they have been initiated.'}
            />
          )
        }
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        paginationPerPage={limit}
        paginationDefaultPage={activePage}
        onRowClicked={(row) =>
          navigate(
            `${getMerchantBaseUrl(merchantName)}/${MerchantDefaultRoutes.DASHBOARD_ROUTE}/${
              DashboardRoutesList.PAYMENT_ROUTE
            }/${PaymentRoutesList.PAYMENT_DETAILS}?txnId=${row.node.transactionId}`,
          )
        }
        onSort={handleSort}
        sortServer
        persistTableHead
        defaultSortFieldId={8}
        defaultSortAsc={false}
        paginationRowsPerPageOptions={[20, 40, 60, 80, 100]}
        pointerOnHover={true}
      />
    </s.MerchantPaymentsTable>
  );
}

export default MerchantsDataTable;
