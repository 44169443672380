export interface ErrorResponse {
  statusCode: number;
  errorCode: string;
  message: string;
  tracer?: string;
  detail?: string;
  errorTypeDescription?: string;
}

export class ApiError extends Error {
  payload: ErrorResponse;

  constructor(message: string, payload: ErrorResponse) {
    super(message);

    this.name = 'ApiError';
    this.payload = payload;

    Object.setPrototypeOf(this, ApiError.prototype);
  }
}

export const handleResponse = async <T = any>(
  res: Response,
  getApiErrorMessage?: (error: ErrorResponse) => string,
): Promise<T> => {
  const body = await res.text();

  if (res.ok) {
    // success response
    const parsedBody = body.length
      ? res.headers.get('Content-Type')?.includes('text/csv') ||
        res.headers.get('Content-Type') === 'text/plain; charset=UTF-8'
        ? body
        : JSON.parse(body)
      : body;

    return body.length ? parsedBody : body;
  }

  // error response
  let payload: ErrorResponse | null = null;

  try {
    // attempt to parse json error
    payload = JSON.parse(body);
  } catch (error) {}

  if (payload) {
    throw new ApiError(getApiErrorMessage?.(payload) ?? payload.message, payload);
  } else {
    throw Error(body);
  }
};

export type PaginatedResponse<T> = {
  items: T;
  totalPages: number;
  totalRecords: number;
};

export const handlePaginatedResponse = async <T = any>(
  res: Response,
  getApiErrorMessage?: (error: ErrorResponse) => string,
): Promise<PaginatedResponse<T>> =>
  handleResponse<T>(res, getApiErrorMessage).then((items) => {
    const totalPages = Number(res.headers.get('Page-Count')) || 0;
    const totalRecords = Number(res.headers.get('Total-Record-Count')) || 0;

    return {
      items,
      totalPages,
      totalRecords,
    };
  });
