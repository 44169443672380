import { PaymentAPIResponseType } from 'api/merchant/dashboard/payments.types';
import * as s from 'assets/styles/Platforms.styles';
import { DateTime } from 'luxon';
import { Metadata } from 'pages/common/Metadata';
import ErrorComponent from 'pages/common/error/ErrorComponent';
import { Fragment, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { toCurrency } from 'utils/currency';
import { capitalizeFirstLowercaseRest } from 'utils/stringUtils';

import { Button, Heading, Icon, Spinner, Text } from '@limepayments/cosmic';

import { PaymentListStatusObj } from '../constants';
import ErrorFetchPaymentListComponent from '../listing/partials/ErrorFetchPaymentListComponent';
import StatusTag from '../listing/partials/StatusTagComponent';

export interface Props {
  onBackButtonClick: () => void;
  detailErrorMsg: string;
  showDetailLoader: boolean;
  paymentDetailObj: PaymentAPIResponseType | null;
}

function PaymentDetails({ onBackButtonClick, detailErrorMsg, showDetailLoader, paymentDetailObj }: Props) {
  useEffect(() => {
    if (!showDetailLoader && paymentDetailObj) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [showDetailLoader, paymentDetailObj]);

  const timelines = useMemo(() => [...(paymentDetailObj?.timelines ?? [])].reverse(), [paymentDetailObj?.timelines]);

  return (
    <Fragment>
      <Helmet>
        <title>Payment Detail</title>
      </Helmet>
      <div className="content-wrap-inner lp-full">
        <div className="header-back mb-28">
          <Button onClick={onBackButtonClick} variant="ghost" size={'small'} testId="payment-detail-back-button">
            <Icon name="ArrowLeft" className="mr-8" />
            Back
          </Button>
        </div>

        {!showDetailLoader && detailErrorMsg.length ? (
          <Fragment>
            <ErrorComponent bodyText={detailErrorMsg} />
            <ErrorFetchPaymentListComponent
              title="Unable to load payment details"
              body={`Sorry, we're unable to load the details for this payment at the moment, please try again later.`}
            />
          </Fragment>
        ) : null}

        {showDetailLoader && (
          <div className="spinner-wrapper">
            <Spinner variant="simple" isVisible label="Loading..." />
          </div>
        )}

        {!showDetailLoader && !detailErrorMsg.length && paymentDetailObj && (
          <Fragment>
            <Heading alignment="left" tagName="h2" variant="xxs" className="text-muted mb-4">
              Payment
            </Heading>
            <div className="header-details">
              <div className="lp-flex lp-flex-column">
                <div className="header-details-inner">
                  <Heading alignment="left" tagName="h2" variant="sm" className="mb-0 mr-16">
                    <span>
                      {toCurrency(
                        paymentDetailObj.purchaseDetails.amount.minorCurrencyUnits,
                        paymentDetailObj.purchaseDetails.amount.currency,
                      )}{' '}
                      {paymentDetailObj.purchaseDetails.amount.currency}
                    </span>
                  </Heading>
                  <StatusTag
                    label={
                      PaymentListStatusObj[paymentDetailObj.purchaseDetails.status as keyof typeof PaymentListStatusObj]
                        .label
                    }
                    variant={
                      PaymentListStatusObj[paymentDetailObj.purchaseDetails.status as keyof typeof PaymentListStatusObj]
                        .varient
                    }
                    tooltipDirection="up"
                    tooltipText={
                      PaymentListStatusObj[paymentDetailObj.purchaseDetails.status as keyof typeof PaymentListStatusObj]
                        .tooltipText
                    }
                  />
                </div>
              </div>
              <div className="lp-flex sm-flex-col sm-w-full">
                <div className="stacked-data" data-testid="payment-detail-order-id-div">
                  <Text
                    tagName="p"
                    variant="caption"
                    className="text-dark-50 m-0"
                    testId="payment-detail-order-id-heading"
                  >
                    Order ID
                  </Text>
                  <Text
                    tagName="p"
                    variant="body-3"
                    className="text-dark m-0 mt-0"
                    testId="payment-detail-order-id-value"
                    isEmphasised
                  >
                    {paymentDetailObj.purchaseDetails.referenceOrderId || paymentDetailObj.purchaseDetails.orderId}
                  </Text>
                </div>
                <div className="stacked-data" data-testid="payment-detail-merchant-name-div">
                  <Text
                    tagName="p"
                    variant="caption"
                    className="text-dark-50 m-0"
                    testId="payment-detail-merchant-name-heading"
                  >
                    Merchant
                  </Text>
                  <Text
                    tagName="p"
                    variant="body-3"
                    className="text-dark m-0 mt-0"
                    testId="payment-detail-merchant-name-value"
                    isEmphasised
                  >
                    {paymentDetailObj.purchaseDetails.merchantName}
                  </Text>
                </div>
                <div className="stacked-data">
                  <Text tagName="p" variant="caption" className="text-dark-50 m-0">
                    Date
                  </Text>
                  <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0" isEmphasised>
                    {DateTime.fromISO(paymentDetailObj.purchaseDetails.createdAt).toFormat('dd/LL/yyyy, HH:mm')}
                  </Text>
                </div>
              </div>
            </div>
            {timelines.length && (
              <s.MerchantsData>
                <Heading alignment="left" tagName="h2" variant="xxs" className="mb-16">
                  Payment timeline
                </Heading>
                <div className="lp-half payment-timeline">
                  {/* <div className="lp-flex mb-28">
                    <div className="icon-back mr-24">
                      <Icon name="ArrowLeft" />
                    </div>
                    <div className="lp-flex lp-flex-column payment-timeline-details">
                      <Text tagName="p" variant="body-2" isEmphasised className="text-dark-50 mb-0">
                        790.00 AUD refunded
                      </Text>
                      <Text tagName="p" variant="body-3" className="text-dark-50 mt-0 mb-8">
                        Reason for refund goes here. It may take 5-10 business days for funds to settle.
                      </Text>
                      <Text tagName="p" variant="caption" className="text-dark-50 mt-0">
                        15 May 2020, 10:23
                      </Text>
                      <div className="lp-w-full mt-12">
                        <button
                          type="button"
                          className={`lp-flex lp-flex-row lp-align-center btn-details ${isActive ? `is-opened` : ``}`}
                          onClick={toggleDetails}
                        >
                          <Icon name="ChevronUp" className="text-purple" />
                          <Text variant="legal" className="link-details">
                            Details
                          </Text>
                        </button>

                        {isOpened && (
                          <div className="lp-w-full lp-flex lp-justify-between mt-16 ">
                            <Text tagName="p" variant="body-3" className="text-dark-50 mt-0">
                              Refund ID
                            </Text>
                            <Text tagName="p" variant="body-3" className="text-dark1 mt-0">
                              3984723984303
                            </Text>
                          </div>
                        )}
                      </div>
                    </div>
                  </div> */}
                  {timelines.map((timeline, key) => {
                    const isSuccess = ['PaymentSucceed', 'PaymentAuthorised', 'PaymentAccepted'].includes(
                      timeline.eventType,
                    );

                    return (
                      <div
                        key={key}
                        className={`lp-flex lp-flex-row mb-28 ${key < (timelines.length ?? 0) - 1 ? 'mb-28' : ''}`}
                      >
                        <div className={isSuccess ? 'icon-check mr-24' : 'icon-back mr-24'}>
                          <Icon name={isSuccess ? 'Tick' : 'ArrowLeft'} />
                        </div>
                        <div className="lp-flex lp-flex-column payment-timeline-details">
                          <Text tagName="p" variant="body-2" isEmphasised className="text-dark-50">
                            {timeline.amount !== undefined && (
                              <>
                                {toCurrency(timeline.amount, paymentDetailObj.purchaseDetails.amount.currency)}{' '}
                                {paymentDetailObj.purchaseDetails.amount.currency}{' '}
                              </>
                            )}
                            {timeline.eventType.split(/(?=[A-Z])/).join(' ')}
                          </Text>
                          <Text tagName="p" variant="caption" className="text-dark-50">
                            {DateTime.fromISO(timeline.eventTime).toFormat('dd LLL yyyy, HH:mm')}
                          </Text>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </s.MerchantsData>
            )}
            {paymentDetailObj.paymentMethod?.paymentMethodType === 'Card' && (
              <s.MerchantsData>
                <Heading alignment="left" tagName="h2" variant="xxs" className="mb-16">
                  Payment method
                </Heading>
                <div className="lp-half lp-flex two-column-block">
                  <ul className="two-column">
                    {/* <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Cardholder name
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      N/A
                    </Text>
                  </li> */}
                    <li className="">
                      <Text tagName="p" variant="body-3" className="text-dark-50">
                        Card number
                      </Text>
                      <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                        **** **** {paymentDetailObj.paymentMethod.cardLast4}
                      </Text>
                    </li>
                    <li className="">
                      <Text tagName="p" variant="body-3" className="text-dark-50">
                        Card expiry date
                      </Text>
                      <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                        {paymentDetailObj.paymentMethod.cardExpiryDate}
                      </Text>
                    </li>
                    <li className="">
                      <Text tagName="p" variant="body-3" className="text-dark-50">
                        Card brand
                      </Text>
                      <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                        {capitalizeFirstLowercaseRest(paymentDetailObj.paymentMethod.cardBrand ?? '-')}
                      </Text>
                    </li>
                    <li className="">
                      <Text tagName="p" variant="body-3" className="text-dark-50">
                        Card type
                      </Text>
                      <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                        {capitalizeFirstLowercaseRest(paymentDetailObj.paymentMethod.cardFunding ?? '-')}
                      </Text>
                    </li>
                  </ul>

                  <ul className="two-column">
                    <li className="">
                      <Text tagName="p" variant="body-3" className="text-dark-50">
                        Issuing bank
                      </Text>
                      <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                        {paymentDetailObj.paymentMethod.cardIssuer ? paymentDetailObj.paymentMethod.cardIssuer : '-'}
                      </Text>
                    </li>
                    <li className="">
                      <Text tagName="p" variant="body-3" className="text-dark-50">
                        Issuing country
                      </Text>
                      <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                        {paymentDetailObj.paymentMethod.cardCountry}
                      </Text>
                    </li>
                    <li className="">
                      <Text tagName="p" variant="body-3" className="text-dark-50">
                        CVV check
                      </Text>
                      <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                        {paymentDetailObj.paymentMethod.cardCVCVerificationPassed ? 'Successful' : '-'}
                      </Text>
                    </li>
                    <li className="">
                      <Text tagName="p" variant="body-3" className="text-dark-50">
                        3DS
                      </Text>
                      <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                        {paymentDetailObj.paymentMethod.card3DSVerificationPassed ? 'Successful' : '-'}
                      </Text>
                    </li>
                  </ul>
                </div>
              </s.MerchantsData>
            )}

            <s.MerchantsData>
              <div className="lp-half lp-flex two-column-block">
                {paymentDetailObj.serviceFees && (
                  <ul className="two-column">
                    <li className="">
                      <Heading alignment="left" tagName="h2" variant="xxs" className="mb-0">
                        Service fees
                      </Heading>
                    </li>
                    <li className="">
                      <Text tagName="p" variant="body-3" className="text-dark-50">
                        Fixed percentage amount
                      </Text>
                      <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                        {toCurrency(
                          paymentDetailObj.serviceFees.variableAmount,
                          paymentDetailObj.purchaseDetails.amount.currency,
                        )}{' '}
                        {paymentDetailObj.purchaseDetails.amount.currency}
                      </Text>
                    </li>
                    <li className="">
                      <Text tagName="p" variant="body-3" className="text-dark-50">
                        Fixed amount
                      </Text>
                      <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                        {toCurrency(
                          paymentDetailObj.serviceFees.fixedAmount,
                          paymentDetailObj.purchaseDetails.amount.currency,
                        )}{' '}
                        {paymentDetailObj.purchaseDetails.amount.currency}
                      </Text>
                    </li>
                    <li className="">
                      <Text tagName="p" variant="body-3" className="text-dark-50">
                        Tax (GST: 10%)
                      </Text>
                      <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                        {toCurrency(
                          paymentDetailObj.serviceFees.taxAmount,
                          paymentDetailObj.purchaseDetails.amount.currency,
                        )}{' '}
                        {paymentDetailObj.purchaseDetails.amount.currency}
                      </Text>
                    </li>
                    <li className="">
                      <Text tagName="p" variant="body-3" isEmphasised className="text-dark-50">
                        TOTAL
                      </Text>
                      <Text tagName="p" variant="body-3" isEmphasised className="text-dark m-0 mt-0">
                        {toCurrency(
                          paymentDetailObj.serviceFees.totalAmount,
                          paymentDetailObj.purchaseDetails.amount.currency,
                        )}{' '}
                        {paymentDetailObj.purchaseDetails.amount.currency}
                      </Text>
                    </li>
                  </ul>
                )}
                <ul className="two-column">
                  <li className="">
                    <Heading alignment="left" tagName="h2" variant="xxs" className="mb-0">
                      Merchant surcharge
                    </Heading>
                  </li>
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Surcharge
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      {toCurrency(
                        paymentDetailObj.purchaseDetails.surchargeAmount,
                        paymentDetailObj.purchaseDetails.amount.currency,
                      )}{' '}
                      {paymentDetailObj.purchaseDetails.amount.currency}
                    </Text>
                  </li>
                  <li className="">
                    <Text tagName="p" variant="body-3" isEmphasised className="text-dark-50">
                      TOTAL
                    </Text>
                    <Text tagName="p" variant="body-3" isEmphasised className="text-dark m-0 mt-0">
                      {toCurrency(
                        paymentDetailObj.purchaseDetails.surchargeAmount,
                        paymentDetailObj.purchaseDetails.amount.currency,
                      )}{' '}
                      {paymentDetailObj.purchaseDetails.amount.currency}
                    </Text>
                  </li>
                </ul>
              </div>
            </s.MerchantsData>

            <s.MerchantsData>
              <div className="lp-half lp-flex two-column-block last">
                <ul className="two-column">
                  <li className="">
                    <Heading alignment="left" tagName="h2" variant="xxs" className="mb-0">
                      Purchase details
                    </Heading>
                  </li>
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Merchant
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      {paymentDetailObj.purchaseDetails.merchantName}
                    </Text>
                  </li>
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Merchant ID
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      {paymentDetailObj.purchaseDetails.merchantId}
                    </Text>
                  </li>
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Order ID
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      {paymentDetailObj.purchaseDetails.referenceOrderId || paymentDetailObj.purchaseDetails.orderId}
                    </Text>
                  </li>
                  <li className="" data-testid="payment-detail-txnid-listing">
                    <Text tagName="p" variant="body-3" className="text-dark-50" testId="payment-detail-txnid-heading">
                      Transaction ID
                    </Text>
                    <Text
                      tagName="p"
                      variant="body-3"
                      className="text-dark m-0 mt-0"
                      testId="payment-detail-txnid-value"
                    >
                      {paymentDetailObj.purchaseDetails.transactionId}
                    </Text>
                  </li>
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Order type
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      {capitalizeFirstLowercaseRest(paymentDetailObj.purchaseDetails.orderType)}
                    </Text>
                  </li>
                  <li className="" data-testid="payment-detail-payment-option-listing">
                    <Text
                      tagName="p"
                      variant="body-3"
                      className="text-dark-50"
                      testId="payment-detail-payment-option-heading"
                    >
                      Payment option
                    </Text>
                    <Text
                      tagName="p"
                      variant="body-3"
                      className="text-dark m-0 mt-0"
                      testId="payment-detail-payment-option-value"
                    >
                      {capitalizeFirstLowercaseRest(paymentDetailObj.purchaseDetails.payType)}
                    </Text>
                  </li>
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Description
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      {paymentDetailObj.purchaseDetails.description}
                    </Text>
                  </li>
                </ul>
                {paymentDetailObj.customerDetails && (
                  <ul className="two-column">
                    <li className="">
                      <Heading alignment="left" tagName="h2" variant="xxs" className="mb-0">
                        Customer details
                      </Heading>
                    </li>
                    <li className="">
                      <Text tagName="p" variant="body-3" className="text-dark-50">
                        Customer name
                      </Text>
                      <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                        {paymentDetailObj.customerDetails.customerName}
                      </Text>
                    </li>
                    <li className="">
                      <Text tagName="p" variant="body-3" className="text-dark-50">
                        Customer ID
                      </Text>
                      <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                        {paymentDetailObj.customerDetails.customerId}
                      </Text>
                    </li>
                    <li className="">
                      <Text tagName="p" variant="body-3" className="text-dark-50">
                        Customer email
                      </Text>
                      <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                        {paymentDetailObj.customerDetails.customerEmailAddress}
                      </Text>
                    </li>
                    <li className="">
                      <Text tagName="p" variant="body-3" className="text-dark-50">
                        Customer phone number
                      </Text>
                      <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                        {paymentDetailObj.customerDetails.customerPhoneNumber ?? '-'}
                      </Text>
                    </li>
                  </ul>
                )}
              </div>
            </s.MerchantsData>

            {!!paymentDetailObj.purchaseDetails.orderMetadata && (
              <s.MerchantsData>
                <div className="lp-half last">
                  <Heading alignment="left" tagName="h2" variant="xxs" className="mb-16">
                    Purchase metadata
                  </Heading>
                  <Metadata metadata={paymentDetailObj.purchaseDetails.orderMetadata} />
                </div>
              </s.MerchantsData>
            )}
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}

export default PaymentDetails;
