import { Address } from 'api/fragments';
import { useMemo } from 'react';

export type UpdateMerchantRequest =
  | UpdateMerchantBusinessProfileRequest
  | UpdateMerchantBrandingRequest
  | UpdateOrderCustomFieldsRequest
  | UpdateMerchantBillingSettingsRequest
  | SetMerchantBillingPaymentSource;

export type StandardMerchant = {
  StandardMerchant: {
    merchantId: string;
    businessProfile: {
      timeZone: string;
      businessName: string;
      businessType: 'company' | 'individual' | 'soletrader' | 'partnership' | 'trust';
      companyType: 'Private' | 'Public';
      website: string;
      supportSite: string;
      industry: string;
      taxCountry: string;
      taxId: string;
      contactPerson: {
        title: string;
        givenName: string;
        familyName: string;
      };
      contactEmailAddress: string;
      supportEmailAddress: string;
      phoneNumber: string;
      address: Address;
      brandName: string;
      cardStatementName: string;
      smsSenderName: string;
    };
    branding: {
      iconUri?: string;
      logoUri?: string;
      logoAltText?: string;
      primaryColor?: string;
    };
    billingDetails: BillingDetails;
    orderCustomFields: CustomField[];
    status: 'Pending' | 'Active' | 'Suspended';
  };
};

export type CustomField = {
  key: string;
  label: string;
  required: boolean;
  visibleForCustomer: boolean;
};

export type BillingDetails = {
  billingNotificationEmails?: Array<string>;
  billingNotificationsEnabled: boolean;
  billingAddress?: Address;
  billingPaymentSourceId?: string;
};

// TODO: ServiceProviderMerchant types
export type ServiceProviderMerchant = {
  ServiceProviderMerchant: Omit<
    StandardMerchant['StandardMerchant'],
    'verificationResult' | 'branding' | 'orderNotificationSettings' | 'orderCustomFields'
  >;
};

export type MerchantResponse = StandardMerchant | ServiceProviderMerchant;

export type AnyMerchant = StandardMerchant['StandardMerchant'] | ServiceProviderMerchant['ServiceProviderMerchant'];

export const useAnyMerchant = (merchantResponse: Nullable<MerchantResponse>) =>
  useMemo<Nullable<AnyMerchant>>(
    () =>
      merchantResponse
        ? 'StandardMerchant' in merchantResponse
          ? merchantResponse.StandardMerchant
          : merchantResponse.ServiceProviderMerchant
        : merchantResponse,
    [merchantResponse],
  );

export type CustomerType = 'consumer' | 'organisation';
export type SettlementType = 'Net' | 'Gross';

export type MerchantGeneralSettingsResponse = {
  settlementType: SettlementType;
  supportedCustomerTypes: Array<CustomerType>;
  createdAt: string;
  updatedAt: string;
};

export type UpdateMerchantBusinessProfileRequest = {
  UpdateMerchantBusinessProfile: {
    timeZone: string;
    businessName: string;
    businessType: string;
    companyType?: string;
    website: string;
    supportSite: string;
    industry: string;
    tradingCountry?: string;
    taxId: string;
    contactPerson: {
      title: string;
      givenName: string;
      familyName: string;
    };
    contactEmailAddress: string;
    supportEmailAddress: string;
    phoneNumber: string;
    address: Address;
    brandName: string;
    cardStatementName: string;
    smsSenderName: string;
  };
};

export type UpdateMerchantBrandingRequest = {
  UpdateMerchantBranding: {
    branding: {
      iconUri?: string;
      logoUri?: string;
      logoAltText?: string;
      primaryColor?: string;
    };
  };
};

export type UpdateMerchantBillingSettingsRequest = {
  UpdateMerchantBillingSettings: {
    billingNotificationEmails?: Array<string>;
    billingNotificationsEnabled?: boolean;
    billingAddress: Address;
  };
};

export type SetMerchantBillingPaymentSource = {
  SetMerchantBillingPaymentSource: {
    paymentSourceId: string;
  };
};

export type UpdateOrderCustomFieldsRequest = {
  UpdateOrderCustomFields: {
    fields: CustomField[];
  };
};
