import { GetMerchantConfigResponse } from 'api/merchant/merchant.types';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export enum CountryType {
  AU = 'AU',
  NZ = 'NZ',
}

export interface IConfig {
  merchantId: string;
  merchantName: string;
  tenantId: string;
  apiBaseUri: string;
  isLoggedIn: boolean | null;
  isLoginPending: boolean;
  merchantEmail: string;
  isMerchantActivated: boolean | null;
  branding: GetMerchantConfigResponse['branding'] | null;
  merchantTaxCountry: CountryType;
  merchantTradingCountry: CountryType;
  alwaysRequest3DS: boolean;
  merchantPublicKey: string;
  orderBaseUri: string;
  marketplaceTags: Array<string>;
  sourceVerificationEnabled: boolean;
}

const initialState: IConfig = {
  merchantId: '',
  merchantName: '',
  tenantId: '',
  apiBaseUri: '',
  isLoggedIn: null,
  isLoginPending: false,
  isMerchantActivated: null,
  merchantEmail: '',
  // get tax country from config endpoint
  merchantTaxCountry: CountryType.AU,
  merchantTradingCountry: CountryType.AU,
  alwaysRequest3DS: false,
  branding: null,
  merchantPublicKey: '',
  orderBaseUri: '',
  marketplaceTags: [],
  sourceVerificationEnabled: false,
};

export const configSlice = createSlice({
  name: 'merchantConfig',
  initialState,
  reducers: {
    setConfig: (state, action: PayloadAction<GetMerchantConfigResponse>) => {
      state.apiBaseUri = action.payload.apiBaseUri;
      state.tenantId = action.payload.tenantId;
      state.merchantId = action.payload.merchantId;
      state.orderBaseUri = action.payload.orderBaseUri;
      state.merchantTaxCountry = action.payload.merchantTaxCountry;
      state.alwaysRequest3DS = action.payload.alwaysRequest3DS;
      state.branding = action.payload.branding;
      state.merchantPublicKey = action.payload.merchantPublicKey || '';
      state.marketplaceTags = action.payload.marketplaceTags || [];
      state.sourceVerificationEnabled = action.payload.sourceVerificationEnabled;
    },
    setMerchantActivated: (state, action: PayloadAction<boolean>) => {
      state.isMerchantActivated = action.payload;
    },
    setMerchantEmail: (state, action: PayloadAction<string>) => {
      state.merchantEmail = action.payload;
    },
    setMerchantName: (state, action: PayloadAction<string>) => {
      state.merchantName = action.payload;
    },
    setLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },
    setLoginPending: (state, action: PayloadAction<boolean>) => {
      state.isLoginPending = action.payload;
    },
  },
});

export const { setConfig, setMerchantActivated, setMerchantEmail, setMerchantName, setLoggedIn, setLoginPending } =
  configSlice.actions;

export default configSlice.reducer;
