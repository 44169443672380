import { getMerchantPaymentDetail } from 'api/merchant/dashboard/payments';
import { PaymentAPIResponseType } from 'api/merchant/dashboard/payments.types';
import { PlatformDashboardPaymentsObj } from 'api/platform/payments.types';
import PlatformLayout from 'layouts/platform/PlatformLayout';
import qs from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { SortOrder, TableColumn } from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/platform/hooks';
import { getPlatformBaseUrl } from 'utils/getBaseUrl';
import getErrorMessage from 'utils/getErrorMessage';

import DashboardRoutesList from '../../constants';
import PaymentRoutesList from '../constants';
import { IPaymentFilterParentObj, IPaymentFilterValuesObj } from '../listing/types';
import PaymentDetails from './PaymentDetails';

export interface Props {
  paymentList: Array<PlatformDashboardPaymentsObj>;
  fetchListLoader: boolean;
  totalRows: number;
  handlePerRowsChange: (newPerPage: number, page: number) => void;
  handlePageChange: (page: number) => void;
  limit: number;
  toggleFilter: (val: boolean) => void;
  filterRecordHandler: (obj: IPaymentFilterValuesObj) => void;
  filterValuesObj: IPaymentFilterParentObj;
  activePage: number;
  handleSort: (selectedColumn: TableColumn<PlatformDashboardPaymentsObj>, sortDirection: SortOrder) => void;
  defaultExportOpened?: boolean;
}

function PaymentDetailPage() {
  const navigate = useNavigate();
  const { txnId } = qs.parse(window.location.search);

  // detail section variables
  const [showDetailLoader, setShowDetailLoader] = useState<boolean>(false);
  const [paymentDetailObj, setPaymentDetailObj] = useState<PaymentAPIResponseType | null>(null);
  const [detailErrorMsg, setDetailErrorMsg] = useState<string>('');

  const { platformName } = useAppSelector((state) => ({
    platformName: state.config.platformName,
  }));

  const detailSectionBackClickhandler = () => {
    navigate(
      `${getPlatformBaseUrl(platformName)}/dashboard/${DashboardRoutesList.PAYMENT_ROUTE}/${
        PaymentRoutesList.PAYMENT_LANDING
      }`,
    );
  };

  const detailClickHandler = useCallback(async () => {
    try {
      if (!txnId) {
        return;
      }

      setShowDetailLoader(true);

      const response = await getMerchantPaymentDetail(txnId as string);
      setPaymentDetailObj(response);
    } catch (error) {
      setDetailErrorMsg(getErrorMessage(error));
    } finally {
      setShowDetailLoader(false);
    }
  }, [txnId]);

  useEffect(() => {
    if (txnId) {
      detailClickHandler();
    }
  }, [txnId, detailClickHandler]);

  return (
    <PlatformLayout activeTab="payment" title="Payment Detail">
      <PaymentDetails
        onBackButtonClick={() => detailSectionBackClickhandler()}
        showDetailLoader={showDetailLoader}
        paymentDetailObj={paymentDetailObj}
        detailErrorMsg={detailErrorMsg}
      />
    </PlatformLayout>
  );
}

export default PaymentDetailPage;
