import { PayoutStatus, PayoutsQueryQuery } from 'api/graphql/generated/graphql';
import { AmountFilterValue, DateFilterValue, SearchFilterOption } from 'pages/common/filters';

export interface IExportFilterObj {
  exportWithAppliedFilter: boolean;
  dateActiveRangeValue: string;
  exportDateRangeValue: string;
  exportColumnsSelectedList: Array<string>;
  exportDataLoader: boolean;
  exportDataCSV: string | null;
  exportSuccessfully: boolean;
  errorMessage: string;
}

export interface IPayoutFilterObj {
  amountChecked: boolean;
  amount: AmountFilterValue | null;
  payoutIdChecked: boolean;
  payoutId: SearchFilterOption | null;
  createdOnChecked: boolean;
  createdOn: DateFilterValue | null;
  availableOnChecked: boolean;
  availableOn: DateFilterValue | null;
  statusChecked: boolean;
  status: PayoutStatus[] | null;
}

export const getFilterCount = (filterObj: IPayoutFilterObj) =>
  [
    filterObj.amountChecked,
    filterObj.payoutIdChecked,
    filterObj.createdOnChecked,
    filterObj.availableOnChecked,
    filterObj.statusChecked,
  ].filter((value) => value).length;

export interface IPayoutFilterObjError {
  amountError: string;
  payoutIdError: string;
  createdOnError: string;
  availableOnError: string;
  statusError: string;
}

export const hasFilterError = (filterObjError: IPayoutFilterObjError) =>
  !!filterObjError.amountError ||
  !!filterObjError.payoutIdError ||
  !!filterObjError.createdOnError ||
  !!filterObjError.availableOnError ||
  !!filterObjError.statusError;

export interface IListingPagePropsObj {
  page: number;
  last: number | null;
  before: string | null;
  first: number | null;
  after: string | null;
}

export type PayoutsQueryEdges = NonNullable<PayoutsQueryQuery['payouts']>['edges'];
