import { TransactionStatus } from 'api/graphql/generated/graphql';
import { toTransactionStatusObj } from 'pages/merchant/dashboard/payment/constants';
import { useCallback } from 'react';

import { Checkbox, CheckboxGroup } from '@limepayments/cosmic';

import { SmallCheckboxes } from './FilterDropdown';

export type TransactionStatusFilterProps = {
  value: TransactionStatus[];
  onChange: (value: TransactionStatus[]) => void;
};

export function TransactionStatusFilter({ value, onChange }: TransactionStatusFilterProps) {
  const onToggle = useCallback(
    (status: TransactionStatus) => {
      onChange(
        value.some((value) => value === status) ? [...value].filter((value) => value !== status) : [...value, status],
      );
    },
    [onChange, value],
  );

  const StatusCheckbox = useCallback(
    ({ status }: { status: TransactionStatus }) => (
      <Checkbox
        variant="body-3"
        id={status}
        label={toTransactionStatusObj(status).label}
        checked={value.some((value) => value === status)}
        onChange={() => onToggle(status)}
      />
    ),
    [onToggle, value],
  );

  return (
    <SmallCheckboxes>
      <CheckboxGroup>
        <StatusCheckbox status={TransactionStatus.Paid} />
        <StatusCheckbox status={TransactionStatus.Refunded} />
        <StatusCheckbox status={TransactionStatus.PartiallyRefunded} />
        <StatusCheckbox status={TransactionStatus.Pending} />
        <StatusCheckbox status={TransactionStatus.Failed} />
        <StatusCheckbox status={TransactionStatus.Cancelled} />
        <StatusCheckbox status={TransactionStatus.Disputed} />
        <StatusCheckbox status={TransactionStatus.NotCaptured} />
        <StatusCheckbox status={TransactionStatus.Incomplete} />
        <StatusCheckbox status={TransactionStatus.Accepted} />
      </CheckboxGroup>
    </SmallCheckboxes>
  );
}
