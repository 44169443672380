/** @jsxImportSource @emotion/react */

import { useMemo } from 'react';

export function Metadata({ metadata }: { metadata: {} }) {
  const json = useMemo(() => {
    try {
      return JSON.stringify(metadata, null, 2);
    } catch (error) {
      return 'Invalid metadata';
    }
  }, [metadata]);

  return (
    <pre
      css={{
        color: 'rgb(var(--lp-colors-neutral-600))',
        fontSize: 12,
        lineHeight: 1.4,
        maxHeight: 200,
        padding: 10,
        border: '1px solid rgb(var(--lp-colors-neutral-200))',
        borderRadius: 2,
        overflow: 'auto',
      }}
    >
      {json}
    </pre>
  );
}
