import {
  PayType,
  TransactionOrderInput,
  TransactionStatus,
  TransactionsQueryQuery,
} from 'api/graphql/generated/graphql';
import { AmountFilterValue, DateFilterValue, SearchFilterOption } from 'pages/common/filters';

export interface IExportFilterObj {
  exportWithAppliedFilter: boolean;
  dateActiveRangeValue: string;
  exportDateRangeValue: string;
  exportColumnsSelectedList: Array<string>;
  exportDataLoader: boolean;
  exportDataCSV: string | null;
  exportSuccessfully: boolean;
  errorMessage: string;
}

export interface IPaymentFilterObj {
  amountChecked: boolean;
  amount: AmountFilterValue | null;
  statusChecked: boolean;
  status: TransactionStatus[] | null;
  customerChecked: boolean;
  customer: SearchFilterOption | null;
  paymentOptionChecked: boolean;
  paymentOption: PayType | null;
  orderIdChecked: boolean;
  orderId: SearchFilterOption | null;
  transactionIdChecked: boolean;
  transactionId: SearchFilterOption | null;
  createdOnChecked: boolean;
  createdOn: DateFilterValue | null;
  availableOnChecked: boolean;
  availableOn: DateFilterValue | null;
}

export const getFilterCount = (filterObj: IPaymentFilterObj) =>
  [
    filterObj.amountChecked,
    filterObj.statusChecked,
    filterObj.customerChecked,
    filterObj.paymentOptionChecked,
    filterObj.orderIdChecked,
    filterObj.transactionIdChecked,
    filterObj.createdOnChecked,
    filterObj.availableOnChecked,
  ].filter((value) => value).length;

export interface IPaymentFilterObjError {
  amountError: string;
  statusError: string;
  customerError: string;
  paymentOptionError: string;
  orderIdError: string;
  transactionIdError: string;
  createdOnError: string;
  availableOnError: string;
}

export const hasFilterError = (filterObjError: IPaymentFilterObjError) =>
  !!filterObjError.amountError ||
  !!filterObjError.statusError ||
  !!filterObjError.customerError ||
  !!filterObjError.paymentOptionError ||
  !!filterObjError.orderIdError ||
  !!filterObjError.transactionIdError ||
  !!filterObjError.createdOnError ||
  !!filterObjError.availableOnError;

export interface IListingPagePropsObj {
  page: number;
  last: number | null;
  before: string | null;
  first: number | null;
  after: string | null;
  orderBy: TransactionOrderInput | null;
}

export type TransactionsQueryEdges = NonNullable<TransactionsQueryQuery['transactions']>['edges'];
