import styled from '@emotion/styled';

export const Wrapper = styled.div`
  height: 100%;
  min-height: 100%;
  display: flex;
  align-items: stretch;
  -ms-box-orient: vertical;
  box-orient: vertical;
  flex-direction: column;
  background-color: white;

  .lp-nowrap {
    white-space: nowrap;
  }

  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .lp-message {
    > div {
      padding: 12px;
      display: inline-flex;
    }
  }

  .build-order-content {
    display: flex;
    min-height: 250px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 767px) {
      min-height: 200px;
    }
  }

  .add-new-item {
    border-radius: 5px;
    margin-bottom: 24px;
    padding: var(--lp-space-xs);
    background-color: rgb(var(--lp-colors-neutral-50));
    border: 1px solid rgb(var(--lp-colors-neutral-200));
  }

  .icon-16px {
    top: 2px;
    width: 16px;
    height: 16px;
    display: flex;
    position: relative;

    svg {
      width: 16px;
      height: 16px;
      fill: rgb(var(--lp-colors-neutral-600));
    }
  }

  .lp-detail-control {
    button + button {
      margin-left: var(--lp-space-xs) !important;
    }
  }

  .lp-align-end {
    @media (max-width: 767px) {
      align-items: flex-start !important;
    }
  }

  .container-sm {
    padding-left: var(--lp-space-md) !important;
    padding-right: var(--lp-space-md) !important;

    @media (max-width: 767px) {
      height: 100%;
      padding-left: var(--lp-space-xs) !important;
      padding-right: var(--lp-space-xs) !important;
    }
  }
  .container-sm,
  .container-body {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;
  }
  .container-body {
    padding-left: var(--lp-space-xxxl);
    padding-right: var(--lp-space-xxxl);

    @media (max-width: 767px) {
      padding-left: var(--lp-space-xs);
      padding-right: var(--lp-space-xs);
    }
    @media screen and (min-width: 768px) and (max-width: 1080px) {
      padding-left: var(--lp-space-md);
      padding-right: var(--lp-space-md);
    }
  }

  .lp-equal-width {
    width: 100%;
    max-width: 50%;

    @media (max-width: 767px) {
      width: 100%;
      max-width: 100%;
    }
    .MuiFormHelperText-root {
      padding-left: 0 !important;
      padding-right: var(--lp-space-sm) !important;
      white-space: normal !important;
    }
  }

  .modal-main {
    max-width: 550px;

    @media (max-width: 767px) {
      max-width: 350px !important;
    }
  }

  .p-24 {
    padding: var(--lp-space-sm);
  }
  .pt-24 {
    padding-top: var(--lp-space-sm);
  }
  .p-28 {
    padding: 28px;
  }
  .pl-16 {
    padding-left: var(--lp-space-xs);
  }
  .pr-16 {
    padding-right: var(--lp-space-xs);
  }
  .pb-24 {
    padding-right: var(--lp-space-sm);
  }
  .pb-8 {
    padding-bottom: var(--lp-space-xxxs);
  }
  .pt-8 {
    padding-top: var(--lp-space-xxxs);
  }
  .bb-1 {
    border-bottom: 1px solid rgba(var(--lp-colors-neutral-200), 1);
  }

  .w-75 {
    width: 100%;
    max-width: 75%;
  }
  .text-center {
    text-align: center;
  }

  .modal-content {
    &.p-28 {
      padding-bottom: 0px !important;
    }
  }

  .illustration-90 {
    width: 90px;
    height: 90px;
    margin-bottom: 6px;
  }
  .illustration-115 {
    width: 115px;
    height: 115px;

    img {
      width: 115px;
      height: 115px;
    }

    @media (max-width: 767px) {
      width: 90px;
      height: 90px;

      img {
        width: 90px;
        height: 90px;
      }
    }
  }

  button,
  .button {
    outline: none !important;
  }

  .lp-inline-flex {
    display: inline-flex;
  }
  .lp-flex-wrap {
    flex-wrap: wrap;
  }
  .lp-align-end {
    align-items: flex-end;
  }

  .lp-w-120 {
    flex-shrink: 0;
    width: 120px !important;
    max-width: 120px !important;
    padding-left: var(--lp-space-xs);
  }

  .currency {
    color: #b3b8cc !important;
    padding-top: 22px;
    padding-left: var(--lp-space-xs);
  }

  .size-11 {
    width: 11px;
    height: 11px;
    margin-left: var(--lp-space-tiny);

    svg {
      width: 11px;
      height: 11px;
    }
  }
  .size-16 {
    width: 16px;
    height: 16px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  span[data-variant] {
    white-space: nowrap;
  }
  span[data-variant='paid'] {
    background: rgba(var(--lp-colors-success-200), 1);
  }

  .sidebar-menu-wrap {
    display: none;

    @media (max-width: 1023px) {
      height: 32px;
      min-width: 120px;
      display: flex !important;
      align-items: center;
      justify-content: flex-end;

      .btn {
        top: auto;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .btn {
      cursor: pointer;
    }
  }

  .fw-medium {
    font-weight: 500;
  }
  .fw-bold {
    font-weight: 700;
  }
  .ml-auto {
    margin-left: auto !important;
  }
  .d-none {
    display: none !important;
  }
  .max-w320 {
    max-width: 315px;
  }
  .container {
    padding-left: var(--lp-space-xxxl);
    padding-right: var(--lp-space-xxxl);

    @media (max-width: 767px) {
      padding-left: var(--lp-space-xs);
      padding-right: var(--lp-space-xs);
    }
    @media screen and (min-width: 768px) and (max-width: 1080px) {
      padding-left: var(--lp-space-md);
      padding-right: var(--lp-space-md);
    }
  }
  .no-min-width {
    min-width: inherit;
  }

  .icon-18px {
    top: 1px;
    width: 20px;
    height: 20px;
    position: relative;
  }
  .text-success {
    color: #2f864e !important;
  }
  .text-warning {
    color: #eda94c !important;
  }

  .sm-pt-24 {
    padding-top: 24px;
  }

  .lp-align-center {
    align-items: center !important;
  }
  .lp-flex-column {
    -ms-box-orient: vertical;
    box-orient: vertical;
    flex-direction: column;
  }
  .lp-justify-between {
    justify-content: space-between;
  }
  .lp-justify-start {
    justify-content: flex-start;
  }
  .lp-justify-end {
    justify-content: flex-end !important;
  }
  .lp-justify-center {
    justify-content: center;
  }
  .text-primary {
    color: #191e33;
  }
  .text-dark {
    color: #3c3c3b;
    &-25 {
      // color: #b3b8cc;
      color: rgb(var(--lp-colors-neutral-400));
    }
  }
  .text-dark1 {
    color: #030305;
  }
  .text-dark2 {
    color: #555c7c;
  }
  .text-purple {
    color: rgba(var(--lp-colors-primary), 1);
  }

  .position-relative {
    position: relative;
  }
  .text-muted {
    color: #8e93ab !important;
  }
  .text-start {
    text-align: left !important;
  }

  .lp-w-auto {
    width: auto !important;
    max-width: inherit !important;
  }

  .lp-neutral-600,
  .text-dark-50 {
    color: rgb(var(--lp-colors-neutral-600));
  }

  // Misc
  .mb-28 {
    margin-bottom: var(--lp-space-sm) !important;
  }
  .mb-8 {
    margin-bottom: var(--lp-space-xxxs) !important;
  }
  .mb-4 {
    margin-bottom: var(--lp-space-tiny) !important;
  }
  .ml-0 {
    margin-left: 0;
  }
  .ml-4 {
    margin-left: var(--lp-space-tiny) !important;
  }
  .ml-16px {
    margin-left: var(--lp-space-xs) !important;
  }
  .mt-0 {
    margin-top: 0;
  }
  .mt-4 {
    margin-top: var(--lp-space-tiny) !important;
  }
  .mt-8 {
    margin-top: var(--lp-space-xxxs) !important;
  }
  .mt-12 {
    margin-top: var(--lp-space-xxs) !important;
  }
  .mt-16 {
    margin-top: var(--lp-space-xs) !important;
  }
  .mt-24 {
    margin-top: var(--lp-space-sm) !important;
  }
  .mt-32 {
    margin-top: var(--lp-space-md) !important;
  }

  .m-0 {
    margin: 0 !important;
  }
  .p-0 {
    padding: 0 !important;
  }
  .mr-4 {
    margin-right: var(--lp-space-tiny) !important;
  }
  .mr-6 {
    margin-right: 6px !important;
  }
  .mr-8 {
    margin-right: var(--lp-space-xxxs) !important;
  }
  .mr-12 {
    margin-right: 10px !important;
  }
  .mr-16 {
    margin-right: var(--lp-space-xs) !important;
  }
  .mr-24 {
    margin-right: var(--lp-space-sm) !important;
  }
  .mr-32 {
    margin-right: var(--lp-space-md) !important;
  }
  .mb-16 {
    margin-bottom: var(--lp-space-xs) !important;
  }
  .mb-24 {
    margin-bottom: var(--lp-space-sm) !important;
  }
  .mb-32 {
    margin-bottom: var(--lp-space-md) !important;
  }
  .mb-12 {
    margin-bottom: var(--lp-space-xxs) !important;
  }

  .is-row-disabled {
    cursor: not-allowed;

    .w-200 {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .w-200 {
    .MuiFormControl-root {
      width: 100%;

      .MuiSelect-root,
      .MuiFormLabel-root {
        padding-left: var(--lp-space-xs);
      }
      .MuiInputBase-input,
      .MuiFormHelperText-root {
        padding-left: var(--lp-space-xxs);
      }
    }
    .size-16 {
      right: var(--lp-space-xxs);
    }
  }

  .btm-4px {
    bottom: 4px;
    position: relative;
  }
  .h-58px {
    width: 100%;
    height: 58px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    button + button {
      margin-top: 0;
      margin-left: 8px;
    }
  }

  .white-bg {
    .MuiFormControl-root {
      background-color: rgb(var(--lp-colors-neutral-white)) !important;
      > div {
        padding-left: var(--lp-space-xs);
      }
      .MuiFormHelperText-root {
        margin-top: 0;
      }
    }
  }
  .select-field {
    .MuiFormControl-root {
      .MuiFormLabel-root {
        left: var(--lp-space-xs);
      }
    }
  }

  .lp-w-half {
    width: 50%;
    max-width: 50%;
  }
  .two-col {
    margin-left: -12px;
    margin-right: -12px;
    width: calc(100% + 24px);
    display: flex;

    @media (max-width: 767px) {
      -ms-box-orient: vertical;
      box-orient: vertical;
      flex-direction: column;
    }

    li,
    > div {
      width: 100%;
      flex: 0 0 50%;
      max-width: 50%;
      padding-left: var(--lp-space-xxs);
      padding-right: var(--lp-space-xxs);

      @media (max-width: 767px) {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;

        &:first-of-type {
          margin-bottom: var(--lp-space-xs) !important;
        }
      }
    }

    &.lp-flex-column {
      -ms-box-orient: vertical;
      box-orient: vertical;
      flex-direction: column;

      li,
      > div {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;

        .MuiFormControl-root,
        .MuiFormControl-root {
          width: 100%;
          max-width: 260px;

          @media (max-width: 767px) {
            max-width: 100%;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .sm-lp-flex-col {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .sm-lp-align-start {
      align-items: flex-start;
    }
    .sm-mt-16 {
      margin-top: var(--lp-space-xs);
    }
    .sm-text-center {
      justify-content: center;
    }
    .sm-w-full,
    .sm-lp-col-lt,
    .sm-lp-col-rt {
      width: 100% !important;
      max-width: 100% !important;
    }
    .sm-lp-col-lt {
      padding-bottom: 36px;
    }
    .sm-lp-col-rt {
      padding-top: 36px;
      border-top: 1px solid #f0f2f9;
      border-left: 0 none !important;
    }
    .sm-pl-0 {
      padding-left: 0 !important;
    }
    .sm-pr-0 {
      padding-right: 0 !important;
    }
    .download-receipt {
      margin-top: 24px;
      margin-left: 0 !important;
      margin-right: 0 !important;

      button {
        width: 100%;
      }
    }
    .sm-p-0 {
      padding: 0;
    }
    .sm-mt-24 {
      margin-top: var(--lp-space-sm);
    }
  }

  .header-back {
    margin-left: 4px;
  }

  @media screen and (min-width: 768px) and (max-width: 1220px) {
    .page-title {
      line-height: 1;
    }
    .header-details {
      position: relative;

      &.md-pb-enable {
        padding-bottom: 84px !important;
      }
    }
    .download-receipt {
      width: 100%;
      position: absolute;
      top: calc(100% - 56px);

      button {
        width: 100%;
      }
    }
  }

  .icon-back,
  .icon-check {
    width: var(--lp-space-sm);
    height: var(--lp-space-sm);
    border-radius: 100%;
  }
  .icon-back {
    background: rgb(var(--lp-colors-neutral-200));
  }
  .icon-check {
    background: rgb(var(--lp-colors-success-200));
  }

  .export-loading {
    width: 100%;
    max-width: 330px;

    .progress {
      width: 100%;
      height: 8px;
      overflow: hidden;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      background: #f0f2f9;
      border-radius: var(--lp-space-tiny);

      &-bar {
        color: #fff;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        text-align: center;
        background-color: #9b6eeb;
        transition: width 0.6s ease;
      }
    }
  }

  .success {
    color: #3c3c3b;
    border-radius: 50%;
    width: var(--lp-space-sm);
    height: var(--lp-space-sm);
    background-color: #d9ece4;
  }

  .header {
    &-back {
      button {
        padding-left: 0;
        padding-right: 0;
        min-width: inherit;
      }
      svg {
        position: relative;
        margin: 0 !important;
        right: var(--lp-space-xxxs);
        transition: right var(--lp-durations-immediate) ease-in-out;
      }

      &:hover,
      &:focus,
      &:active {
        svg {
          right: var(--lp-space-xxs);
        }
      }
    }

    &-details {
      display: flex;
      align-items: center;
      padding-bottom: var(--lp-space-sm);
      border-bottom: 1px solid rgba(var(--lp-colors-neutral-200), 1);

      @media (max-width: 767px) {
        -ms-box-orient: vertical;
        box-orient: vertical;
        flex-direction: column;
        align-items: flex-start;
      }

      &-inner {
        display: flex;
        align-items: center;

        @media (max-width: 767px) {
          margin-bottom: var(--lp-space-sm);
        }
      }
    }
  }
  .stacked-data {
    display: flex;
    line-height: 20px;
    -ms-box-orient: vertical;
    box-orient: vertical;
    flex-direction: column;
    margin-left: calc(var(--lp-space-xxl) - 6px);

    @media (max-width: 767px) {
      margin-left: 0;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: var(--lp-space-xs);

      p {
        font-size: 14px !important;

        &:first-of-type {
          padding-right: var(--lp-space-lg);
        }
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
    @media (max-width: 1023px) {
      &.md-stacked-data {
        margin-left: 0;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: var(--lp-space-xs);

        p {
          font-size: 14px !important;

          &:first-of-type {
            padding-right: var(--lp-space-lg);
          }
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
  .text-dark {
    color: #191e33;
    &-50 {
      color: #555c7c;
    }
  }
  @media (max-width: 767px) {
    .sm-flex-col {
      -ms-box-orient: vertical;
      box-orient: vertical;
      flex-direction: column;
    }
  }
  @media (max-width: 640px) {
    .xs-flex-col {
      -ms-box-orient: vertical;
      box-orient: vertical;
      flex-direction: column;
    }
  }
  @media screen and (min-width: 641px) and (max-width: 767px) {
    .sm-ml-3 {
      margin-left: var(--lp-space-xxs) !important;
    }
  }

  .form-control {
    width: 100%;
    height: 32px;
    outline: none;
    font-size: 14px;
    border-radius: 4px;
    font-family: var(--lp-fonts-body-1);
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    padding: var(--lp-space-tiny) var(--lp-space-xxxs);
    background: rgb(255, 255, 255) none repeat scroll 0% 0%;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 1px inset !important;

    &.radius-0 {
      border-radius: 0;
    }
  }

  .mx-16px {
    margin-left: var(--lp-space-xs) !important;
    margin-right: var(--lp-space-xs) !important;
  }

  .selectdropdown {
    width: 100%;
    height: 32px;
    position: relative;

    select {
      width: 100%;
      margin: 0px;
      padding: 6px;
      appearance: none;
      border: 0px none;
      display: block;
      font-size: 13px;
      line-height: 20px;
      position: relative;
      border-radius: 4px;
      color: rgb(51, 51, 51);
      box-sizing: border-box;
      font-family: var(--lp-fonts-body-1);
      background: rgb(255, 255, 255) none repeat scroll 0% 0%;
      box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 1px inset !important;
    }
    option {
      font-family: Arial, san-serif;
    }
    .arrow-down {
      top: 50%;
      z-index: 2;
      right: 6px;
      width: 20px;
      height: 20px;
      content: ' ';
      display: block;
      color: #191e33;
      cursor: pointer;
      position: absolute;
      border-radius: 4px;
      pointer-events: none;
      transform: translateY(-50%);
    }
  }

  .filter-search {
    .form-control {
      border: 0 none;
      border-radius: 0;
      box-shadow: none;
      padding: var(--lp-space-xs);
      border-bottom: 1px solid rgba(var(--lp-colors-neutral-200), 1);

      &.border-bottom {
        height: auto;
        border: 0 none !important;
        padding: var(--lp-space-xxs) !important;
        border-bottom: 1px solid rgba(var(--lp-colors-neutral-200), 1); !important;
      }
    }
    .button-search {
      top: 10px;
      right: var(--lp-space-xxxs);
      margin: 0;
      padding: 0;
      width: 24px;
      height: 24px;
      min-width: inherit;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;

      svg {
        fill: #191e33;
      }
    }
  }

  /* Flyout Menu */
  .navbar {
    border-radius: 5px;
  }

  .sd-close {
    top: 22px;
    right: 26px;
    position: absolute;
    width: 24px;
    height: 24px;
    cursor: pointer;
    background-color: transparent;
    border-radius: var(--lp-space-tiny);
    opacity: 0;
    transform: scale(0);
    transition: all 0.3s ease;
    transition-duration: 0.7s, 0.6s, 0.7s;
    transition-property: color, opacity, transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }

  .sidebar-menu {
    top: 0;
    z-index: 1;
    right: -100%;
    width: 269px;
    color: #555c7c;
    font-size: 14px;
    position: fixed;
    font-weight: 400;
    transition: 0.5s;
    min-height: 100vh;
    letter-spacing: 0.15px;
    background-color: #f8f8fb;
    font-family: var(--lp-fonts-body-1);
    box-shadow: 0px 16px 48px -8px rgba(0, 0, 0, 0.16);

    &.is-active {
      right: 0;

      .sd-close {
        opacity: 1;
        transform: scale(1);
      }
    }
    &-inner {
      height: 100%;
      display: flex;
      min-height: 100vh;
      align-items: stretch;
      -ms-box-orient: vertical;
      box-orient: vertical;
      flex-direction: column;

      .sd-body {
        flex-grow: 1;
      }
      .sd-header,
      .sd-body,
      .sd-footer {
        flex-shrink: 0;
      }
    }
  }

  .sd-header {
    height: 60px;
    display: flex;
    color: #191e33;
    font-size: 14px;
    font-weight: 700;
    align-items: center;
    justify-content: space-between;
  }
  .sidebar-menu-overlay {
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    transition: 0.5s;
    visibility: hidden;
    background-color: rgba(3, 3, 5, 0.3);

    &.is-enabled {
      opacity: 1;
      visibility: visible;
    }
  }

  .sd-header,
  .sd-body,
  .sd-footer {
    padding: var(--lp-space-xs);
  }

  .sd-body {
    overflow-x: hidden;
    overflow-y: auto !important;
    max-height: calc(100vh - 160px);

    .submenu-title {
      margin-bottom: 14px;
      border-bottom: 1px solid rgba(var(--lp-colors-neutral-200), 1);
      padding-bottom: var(--lp-space-xxs);
    }

    > ul {
      margin: 0;
      padding: 0;
      width: 100%;
      display: inline-block;

      li {
        list-style: none;
        margin-bottom: 0;

        &.divider {
          margin: 10px 0;
          border-bottom: 1px solid rgba(var(--lp-colors-neutral-200), 1);
        }
      }
    }
    .sd-link {
      width: 100%;
      padding: 10px;
      color: #555c7c;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-decoration: none;
      font-family: var(--lp-fonts-body-1);
      -webkit-transition: all 0.2s ease 0.25s;
      -moz-transition: all 0.2s ease 0.25s;
      -ms-transition: all 0.2s ease 0.25s;
      -o-transition: all 0.2s ease 0.25s;
      transition: all 0.2s ease 0.25s;

      &:hover,
      &:focus,
      &:active {
        color: rgba(var(--lp-colors-primary), 1);
      }
      &.is-active {
        color: rgba(var(--lp-colors-primary), 1);
        font-weight: 900;
      }
    }
  }
  .sd-footer {
    ul {
      li {
        display: block;
        padding: 6px 10px;

        &.is-active {
          font-weight: 700;
        }
        .sd-link {
          font-weight: 500;
          &:hover,
          &:focus,
          &:active {
            color: rgba(var(--lp-colors-primary), 1);
          }
        }
      }
    }
  }
  .sd-submenu {
    top: 0px !important;
    right: 0px;
    width: 100%;
    max-width: 269px;
    height: 100%;
    display: block;
    position: fixed !important;
    overflow: hidden;
    text-align: left;
    z-index: 99999999;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -o-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -o-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background-color: #f8f8fb;

    &.navigation-active {
      width: 100%;
      -webkit-transform: translateX(0px);
      -moz-transform: translateX(0px);
      -o-transform: translateX(0px);
      -ms-transform: translateX(0px);
      transform: translateX(0px);
    }

    .sd-header {
      padding-top: var(--lp-space-sm);
      padding-right: 26px;

      .sd-close2 {
        cursor: pointer;
        border: 0 none !important;
        background-color: transparent !important;
      }
      .sd-back {
        color: #191e33;
        min-width: inherit;
        margin: 0 !important;
        padding: 0 0 0 10px !important;
        transition: none !important;
        border: 0 none !important;
        background-color: transparent !important;

        svg {
          fill: #191e33;
          margin: 0 !important;
          padding: 0 !important;
        }
      }
    }
  }

  .payment-timeline {
    z-index: 2;
    position: relative;

    &::before {
      top: 0;
      z-index: -1;
      left: var(--lp-space-xxs);
      content: '';
      width: 1px;
      height: 100%;
      position: absolute;
      max-height: calc(100% - 80px);
      /*max-height: calc(100% - 30px);*/
      background-color: rgb(var(--lp-colors-neutral-200));
    }

    &-details {
      margin-top: -4px;
    }

    .single-row {
      &::before {
        max-height: 0;
      }
    }
  }
  .bg-sky-500 {
    background: rgb(var(--lp-colors-sky-500));
  }

  .icon-dot {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dot-8 {
    width: 8px;
    height: 8px;
    display: flex;
    border-radius: 50%;
    background-color: rgba(var(--lp-colors-neutral-800), 1);
  }

  .btn-details {
    cursor: pointer;
    .link-details {
      font-weight: 500;
      text-decoration-line: underline;
      color: rgb(var(--lp-colors-primary));
      font-size: var(--lp-font-size-link-legal);
      line-height: var(--lp-line-height-link-legal);
      letter-spacing: var(--lp-letter-spacing-link-legal);
      transition: all 0.2s ease;
    }
    svg {
      transition: all 0.2s ease;
    }
    &.is-opened {
      svg {
        transform: rotate(180deg);
      }
    }
    &:hover {
      .link-details {
        opacity: 0.75;
      }
    }
  }

  .transaction-empty {
    display: flex;
    align-items: center;
    -ms-box-orient: vertical;
    box-orient: vertical;
    flex-direction: column;
    justify-content: center;
    min-height: calc(100vh - 370px);

    @media (max-width: 767px) {
      max-width: calc(100% - 24px);
      min-height: calc(100vh - 350px);
    }

    &-inner {
      margin: 0 auto;
      max-width: 360px;
      text-align: center;
      display: flex;
      align-items: center;
      -ms-box-orient: vertical;
      box-orient: vertical;
      flex-direction: column;
      justify-content: center;

      &.w-378 {
        max-width: 378px;

        @media (max-width: 767px) {
          max-width: 100%;
        }
      }

      @media (max-width: 767px) {
        max-width: 100%;
      }
    }
  }

  .table-summary {
    thead,
    tbody,
    tfoot {
      th,
      td {
        padding: 4px 8px;
        font-size: 14px;
        line-height: 20px;
        text-align: right;
        letter-spacing: 0.15px;
        font-family: var(--lp-fonts-body-1);
        color: rgb(var(--lp-colors-neutral-600));

        &:nth-of-type(1) {
          text-align: left;
        }
      }
    }
    thead {
      th {
        background-color: rgb(var(--lp-colors-neutral-50));
      }
    }
    tfoot,
    thead {
      th {
        font-weight: 700;

        &:first-of-type {
          border-radius: var(--lp-space-tiny) 0 0 var(--lp-space-tiny);
        }
        &:last-of-type {
          border-radius: 0 var(--lp-space-tiny) var(--lp-space-tiny) 0;
        }
      }
    }
    tbody {
      td {
        padding: var(--lp-space-xxxs);
        border-bottom: 1px solid rgb(var(--lp-colors-neutral-100));
      }
    }
    tfoot {
      th {
        border-top: 1px solid rgb(var(--lp-colors-neutral-100));
      }
    }
  }

  .item-breakdown {
    margin-top: var(--lp-space-xxs);
    margin-bottom: var(--lp-space-sm);

    .table-summary {
      tfoot,
      thead {
        th {
          padding: var(--lp-space-xxxs);

          &:first-of-type {
            padding-left: var(--lp-space-sm);
          }
          &.last-child {
            max-width: 20px;
          }
        }
      }
      tbody {
        td {
          &:first-of-type {
            padding-left: var(--lp-space-sm);
          }
          &.last-child {
            max-width: 20px;
          }
        }
        tr {
          &:last-of-type {
            td {
              border-bottom: 0 none;
            }
          }
        }
      }

      .lp-edit-action {
        display: flex;
        position: relative;
        justify-content: flex-end;

        .lp-edit-action-holder {
          display: flex;
          justify-content: flex-end;

          .edit-action-button {
            width: 20px;
            height: 20px;
            display: flex;
            border: 0 none;
            align-items: center;
            background-color: transparent;

            svg {
              width: 20px;
              height: 20px;
              fill: #793DE4;
            }
          }
        }
      }
    }
    .action-dropdown {
      top: calc(100% + 8px);
      right: 0px;
      width: 154px;
      z-index: 3;
      padding: var(--lp-space-tiny);
      position: absolute;
      background-color: #fff;
      display: flex;
      border-radius: 5px;
      align-items: stretch;
      -ms-box-orient: vertical;
      box-orient: vertical;
      flex-direction: column;
      border-radius: var(--lp-space-xxxs);
      box-shadow: 0px 8px 24px -4px rgba(0, 0, 0, 0.08);

      Button {
        width: 100%;
        text-align: left;
        padding-left: var(--lp-space-xxxs);
        padding-right: var(--lp-space-xxxs);
        justify-content: flex-start;
        color: rgba(var(--lp-colors-neutral-600), 1);

        svg {
          margin-left: 0;
          margin-right: 4px;
          fill: currentColor;
        }

        &:hover {
          color: #793DE4;
          background-color: rgba(var(--lp-colors-neutral-50), 1);

          svg {
            fill: currentColor;
          }
        }
      }
    }
  }

  .equal-radio-btn {
    div[role='radiogroup'] {
      width: 100%;
      fieldset {
        width: calc(33.3333% - var(--lp-space-xxs));
      }

      @media (max-width: 767px) {
        -ms-box-orient: vertical;
        box-orient: vertical;
        flex-direction: column;

        fieldset {
          min-width: 180px;
        }
      }
    }
  }
  .payouts-switch {
    > div {
      display: flex;
      label {
        order: 1;
      }
      div {
        order: 2;
        margin-left: var(--lp-space-xxxs);
      }
    }
  }
  .lp-tags {
    .tag {
      padding: 4px 8px 4px 12px;
      background-color: rgba(var(--lp-colors-neutral-200), 1);
      margin-right: var(--lp-space-xxxs);
      margin-bottom: var(--lp-space-xxxs);
      border-radius: var(--lp-space-xs);

      p {
        top: -1px;
        font-size: 14px;
        position: relative;
      }

      &-remove {
        width: 20px;
        height: 20px;
        margin-left: var(--lp-space-tiny);

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  .lp-w-120 {
    button {
      svg {
        width: 24px;
        height: 24px;
        flex-shrink: 0;
        margin-left: 0;
        margin-right: var(--lp-space-xs);
      }
    }
  }
  .lp-link-primary {
    color: rgba(var(--lp-colors-primary), 1);
    text-decoration: underline;
  }

  // DateRange Picker
  .react-date-picker,
  .react-daterange-picker {
    width: 100%;
    max-width: 328px;
    background-color: #fff;

    &__wrapper {
      border: 0 none;
      color: #555c7c;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.25px;
      padding: var(--lp-space-xs) var(--lp-space-xxxs);
      border-bottom: 1px solid rgba(var(--lp-colors-neutral-200), 1);
      justify-content: flex-start;

      button {
        margin-left: 0 !important;
      }
    }
    &__range-divider {
      margin: 0 6px;
    }

    &__clear-button,
    &__calendar-button {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease 0.25s;

      svg {
        margin: 0 !important;
      }

      &:hover {
        opacity: 0.75;
      }
    }
    &__clear-button {
      margin-top: 1px !important;
      width: var(--lp-space-xs);
      height: var(--lp-space-xs);
      padding: 2px;
      right: 30px;
      border-radius: 50%;
      position: absolute;
      background-color: #191e33;

      svg {
        stroke: #fff !important;
        stroke-width: 3px !important;
      }
    }
    &__calendar-button {
      padding: 0;
      right: 2px;
      width: 22px;
      height: 22px;
      position: absolute;

      svg {
        width: 22px;
        height: 22px;
        fill: #191e33 !important;
      }
    }

    .react-calendar__navigation__prev2-button,
    .react-calendar__navigation__next2-button {
      display: none !important;
    }
    .react-calendar__navigation__prev-button,
    .react-calendar__navigation__next-button {
      width: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease 0.25s;
      font-size: 0px;
      position: relative;

      &::before {
        top: 2px;
        left: 2px;
        content: '';
        width: 24px;
        height: 24px;
        position: absolute;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3E%3Cpath fill='rgba(25, 30, 51, 1)' d='m10.707 16.707 4-4a1 1 0 0 0 0-1.414l-4-4a1 1 0 0 0-1.414 1.414L12.586 12l-3.293 3.293a1 1 0 1 0 1.414 1.414z'/%3E%3C/svg%3E");
        background-size: 24px 24px;
        background-repeat: no-repeat;
        background-cposition: center center;
      }
    }
    .react-calendar__navigation__prev-button {
      &::before {
        transform: rotate(180deg);
      }
    }
  }

  .react-daterange-picker {
    &__inputGroup {
      padding: 0;
      min-width: 90px;
      max-width: 90px;

      input[name='daterange_from'],
      input[name='daterange_to'] {
        max-width: 90px;
      }

      &__input {
        font-size: 16px;
        outline: none !important;

        &:invalid {
          background: rgba(255, 255, 255, 0.1);
        }
      }
    }
  }

  .w-449,
  .w-656 {
    .react-daterange-picker {
      max-width: 100% !important;

      @media (min-width: 992px) {
        max-width: 85% !important;
      }

      &__wrapper {
        font-size: 16px;
      }

      &__inputGroup {
        min-width: 120px;
        max-width: 120px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        input {
          width: 120px;
          max-width: 120px;
        }
      }
    }
  }

  .w-656 {
    .react-daterange-picker {
      @media (min-width: 992px) {
        max-width: 328px !important;
      }
    }
  }

  .react-date-picker {
    &__inputGroup {
      padding: 0;
      min-width: 160px;
      max-width: 160px;

      input[name='daterange_from'],
      input[name='daterange_to'] {
        max-width: 160px;
      }

      &__input {
        outline: none !important;
      }
    }
    &__calendar-button {
      margin-top: 2px !important;
    }
    &__clear-button {
      margin-top: 5px !important;
    }
  }

  .bb-1 {
    border-bottom: 1px solid #f0f2f9;
  }
  .pb-16 {
    padding-bottom: var(--lp-space-xs);
  }
  .pb-32 {
    padding-bottom: var(--lp-space-md);
  }
  .pb-0 {
    padding-bottom: 0 !important;
  }
  .lp-col-lt,
  .lp-col-rt {
    width: 100%;
    max-width: 50%;
  }
  .lp-col-rt {
    padding-right: var(--lp-space-md);
  }
  .lp-col-rt {
    border-left: 1px solid #f0f2f9;
    padding-left: var(--lp-space-md);
    padding-right: var(--lp-space-md);
  }

  .branding-details {
    padding-top: var(--lp-space-md);

    @media (min-width: 768px) {
      padding-top: 0;
      padding-left: var(--lp-space-md);
    }
  }
  .avatar-100 {
    width: 100px;
    height: 100px;
    flex-shrink: 0;
    overflow: hidden;
    border-radius: 50%;
    background-color: #f0f2f9;
    -webkit-transition: all 0.2s ease 0.25s;
    -moz-transition: all 0.2s ease 0.25s;
    -ms-transition: all 0.2s ease 0.25s;
    -o-transition: all 0.2s ease 0.25s;
    transition: all 0.2s ease 0.25s;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      color: #b3b8cc;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      letter-spacing: 0;
      font-weight: 500;
    }

    &.hoppy {
      background-color: #9bd4e3;
    }
    &.laddr {
      background-color: #e375af;
    }
    &.puma {
      background-color: #f3aa55;
    }
    &.fisher {
      background-color: #9b72e8;
    }
    &.moco {
      background-color: #fd6f4b;
    }
    &.anna {
      background-color: #45a279;
    }
  }

  // Setting Dropdown
  .setting-dropdown {
    top: 80px;
    right: 0px;
    width: 100%;
    max-width: 200px;
    z-index: 3;
    position: absolute;
    background-color: #fff;
    display: flex;
    align-items: stretch;
    -ms-box-orient: vertical;
    box-orient: vertical;
    flex-direction: column;
    border-radius: var(--lp-space-xxxs);
    box-shadow: var(--lp-shadows-medium);
    padding: var(--lp-space-sm);
    padding-bottom: var(--lp-space-xs);

    &::before,
    &::after {
      width: 0;
      height: 0;
      content: '';
      bottom: 100%;
      position: absolute;
      pointer-events: none;
      border: solid transparent;
    }
    &::after {
      right: 36px;
      border-width: 8px;
      border-color: rgba(136, 183, 213, 0);
      border-bottom-color: #fff;
    }
    &::before {
      right: 35px;
      border-color: rgba(194, 225, 245, 0);
      border-bottom-color: #dde0ea;
      border-width: 9px;
    }

    &-header {
      border-bottom: 2px solid #ecf1f8;
      margin-bottom: var(--lp-space-xxs);
      padding-bottom: var(--lp-space-sm);
    }
    .btnText,
    .userRole {
      color: rgba(var(--lp-colors-neutral-600), 1);
    }
    button {
      padding: 0;
      text-align: left;
      justify-content: flex-start;
      color: rgba(var(--lp-colors-neutral-600), 1);
    }
  }

  // New Css changes
  .lp-flex-col {
    display: flex;
    flex-direction: column;
  }
  .text-caption {
    font-family: var(--lp-fonts-caption);
    font-weight: var(--lp-font-weight-caption);
    font-size: var(--lp-font-size-caption);
    line-height: var(--lp-line-height-caption);
    letter-spacing: var(--lp-letter-spacing-caption);
    color: rgba(var(--lp-colors-neutral-600), 1);
  }
  .text-body-3 {
    font-family: var(--lp-fonts-body-3);
    font-weight: var(--lp-font-weight-body-3);
    font-size: var(--lp-font-size-body-3);
    line-height: var(--lp-line-height-body-3);
    letter-spacing: var(--lp-letter-spacing-body-3);
    color: rgba(var(--lp-colors-neutral-800), 1);
  }

  @media screen and (min-width: 768px) and (max-width: 980px) {
    .page-title {
      font-size: var(--lp-font-size-heading-md) !important;
    }
  }

  .merchant-controls {
    button {
      margin-left: 0 !important;

      + button {
        margin-left: var(--lp-space-xs) !important;
      }
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1150px) {
    .merchant-controls {
      justify-content: center !important;
    }
    .md-lp-spacebetween {
      justify-content: space-between !important;
    }
    .lp-flexContainer {
      .order-1 {
        //margin-right: var(--lp-space-xxs) !important;
        margin-bottom: var(--lp-space-xxs) !important;

        &.md-mb-3 {
          margin-bottom: var(--lp-space-xxs) !important;
        }
        &.md-mb-0 {
          margin-bottom: 0 !important;
        }
      }
      .order-2 {
        margin-bottom: var(--lp-space-xxs) !important;

        & > button {
          margin-left: 0 !important;

          + button {
            margin-left: var(--lp-space-xxs) !important;
          }
        }

        &.mb-0 {
          margin-bottom: 0 !important;
        }
      }
    }
    .md-lp-flex-col {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;

      .page-title {
        margin-bottom: var(--lp-space-sm) !important;
      }
    }
  }

  .header-details {
    .filter-dropdown {
      top: calc(100% - var(--lp-space-sm));

      .filter-header {
        .button-close {
          min-width: inherit;
        }
        &.mb-0 {
          margin-bottom: 0 !important;
        }
      }
      .filter-body {
        padding: var(--lp-space-sm);

        .m-loading {
          min-height: 130px;
        }
      }
    }
  }

  .modal-body.h90vh {
    overflow-y: auto;
    max-height: calc(100vh - 220px);

    @media (max-width: 767px) {
      max-height: calc(100vh - 230px);
    }
  }

  .separator {
    margin: 32px 0;
    border-bottom: 1px solid rgba(var(--lp-colors-neutral-100), 1);
  }

  .modal-body-loader {
    padding: var(--lp-space-md) 0;
    > div {
      > div {
        img {
          margin-bottom: 0;
        }
        .lp-text-neutral-600 {
          display: none;
        }
      }
    }
  }

  .table-actions {
    width: 32px;
    height: 32px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    @media (max-width: 767px) {
      background-color: #fff;
    }

    > button {
      padding: 0;
      width: 32px;
      height: 32px;
      display: flex;
      flex-shrink: 0;
      max-width: 32px;
      min-width: inherit;
      position: relative;
      border-radius: 8px;
      align-items: center;
      justify-content: center;
      outline: none !important;
      box-shadow: none !important;
      background-color: transparent !important;

      &:hover,
      &:active,
      &:focus {
        background-color: transparent !important;

        &::before {
          background-color: transparent !important;
        }
      }
    }
  }

  .rdt_TableRow {
    &:hover {
      .table-actions {
        @media (max-width: 767px) {
          background-color: #f0f2f9 !important;
          border-radius: 6px 0 0 6px;
        }
      }
    }
  }

  .sticky-sidebar {
    top: 80px;
    position: sticky;
    position: -webkit-sticky;
  }

  .payment-switch {
    p {
      font-size: 14px;
    }
    > div {
      flex-direction: row-reverse;

      label {
        min-width: 35px;
        margin-right: 8px;
      }
    }
  }

  .well {
    padding: 10px;
    border-radius: 4px;
    background-color: rgba(var(--lp-colors-neutral-100), 1);
  }

  .top-1px {
    top: -1px;
    position: relative;
  }

  .btn-copy {
    width: auto;
    height: auto;
    font-size: 10px;
    font-weight: 700;
    min-width: inherit;
    padding: 0 !important;
    background-color: transparent !important;

    svg {
      width: 16px;
      height: 16px;
    }
  }
  
  .disabled-link {
    cursor: default;
    color: rgb(var(--lp-colors-neutral-400));
  }

  .fs-14 {
    font-size: var(--lp-font-size-link-sm);
  }
  .export-message {
    > div {
      padding: 0;
      border: 0 none;
      box-shadow: none;
      background-color: transparent;
    }
  }
  .lp-w-half.checkbox {
    label {
      p {
        top: -3px;
        position: relative;
      }
    }
  }
`;

export const HeaderToggle = styled.div`
  top: 16px;
  right: 12px;
  width: 32px;
  height: 32px;
`;

export const HeaderSearch = styled.div`
  top: 22px;
  right: 88px;
  width: 22px;
  height: 22px;
`;

export const HeaderHelp = styled.div`
  top: 22px;
  right: 50px;
  width: 22px;
  height: 22px;
`;

export const MerchantLoading = styled.div`
  margin: 0;
  width: 100%;
  min-height: 400px;
  display: flex;
  -ms-box-orient: vertical;
  box-orient: vertical;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Main = styled.div`
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  -ms-box-orient: vertical;
  box-orient: vertical;
  flex-direction: column;
  align-items: stretch;
  background-color: #fff;

  .rdrDays {
    button {
      border: 0 none !important;

      &:hover {
        border: 0 none !important;
      }
    }
  }
`;

export const Header = styled.header`
  top: 0;
  z-index: 5;
  padding: 0;
  min-height: 66px;
  position: sticky;
  background-color: #fff;
  border-bottom: 1px solid rgba(var(--lp-colors-neutral-200), 1);
  // box-shadow: var(--lp-shadows-medium);
  box-shadow: 0px 2px 8px -2px rgba(0, 0, 0, 0.04);

  > .container {
    position: relative;
    padding-left: var(--lp-space-md) !important;
    padding-right: var(--lp-space-md) !important;

    @media (max-width: 1023px) {
      height: 100%;
      padding-left: var(--lp-space-xs) !important;
      padding-right: var(--lp-space-xs) !important;
    }
  }

  .header-icon {
    display: flex;
    -ms-box-orient: vertical;
    box-orient: vertical;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    background-color: #fff;
    border-radius: var(--lp-space-tiny);
  }

  .header-main {
    display: flex;
    // position: relative;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1023px) {
      height: 100%;
    }
  }
  .header-menu {
    list-style: none;
    margin-left: var(--lp-space-xs);

    @media (max-width: 1023px) {
      display: none !important;
    }

    &-items {
      width: 100%;
      height: 66px;
      color: #191e33;
      display: block;
      cursor: pointer;
      list-style: none;
      position: relative;
      padding-left: var(--lp-space-xs);
      padding-right: var(--lp-space-xs);

      @media (max-width: 767px) {
        padding-left: var(--lp-space-xxs);
        padding-right: var(--lp-space-xxs);
      }

      p,
      a,
      span {
        margin: 0;
        padding: 0;
        height: 66px;
        display: flex;
        font-weight: 500;
        line-height: 66px;
        align-items: center;
        white-space: nowrap;
        justify-content: center;
      }

      &::before {
        left: 0;
        content: '';
        width: 100%;
        height: 4px;
        bottom: 0;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        background-color: rgba(var(--lp-colors-primary), 1);
        border-top-left-radius: var(--lp-space-tiny);
        border-top-right-radius: var(--lp-space-tiny);
        -webkit-transition: all 0.2s ease 0.25s;
        -moz-transition: all 0.2s ease 0.25s;
        -ms-transition: all 0.2s ease 0.25s;
        -o-transition: all 0.2s ease 0.25s;
        transition: all 0.2s ease 0.25s;
      }

      &.is-active {
        &::before {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  .header-logo {
  }

  .header-logo {
    height: 32px;
    max-height: 32px;
    display: flex;
    align-items: center;

    img {
      max-height: 32px;
    }

    p {
      color: #555c7c;
    }

    &-text {
      padding-left: var(--lp-space-xxs);

      @media (max-width: 767px) {
        display: none;
      }
    }

    .avatar {
      width: var(--lp-space-md);
      height: var(--lp-space-md);
      overflow: hidden;
      border-radius: 50%;
      border: 1px solid #efeff3;
      background-color: #f0f2f9;
      -webkit-transition: all 0.2s ease 0.25s;
      -moz-transition: all 0.2s ease 0.25s;
      -ms-transition: all 0.2s ease 0.25s;
      -o-transition: all 0.2s ease 0.25s;
      transition: all 0.2s ease 0.25s;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        color: #fff;
        letter-spacing: 2px;
      }

      &.hoppy,
      &.laddr,
      &.puma,
      &.fisher,
      &.moco,
      &.anna {
        border: 0 none;

        p {
          color: #fff;
        }
      }

      &.hoppy {
        background-color: #9bd4e3;
      }
      &.laddr {
        background-color: #e375af;
      }
      &.puma {
        background-color: #f3aa55;
      }
      &.fisher {
        background-color: #9b72e8;
      }
      &.moco {
        background-color: #fd6f4b;
      }
      &.anna {
        background-color: #45a279;
      }
    }
  }

  .header-menu,
  .header-main-inner,
  .header-main-action {
    display: flex;
    align-items: center;
  }
  .header-main-action {
    li {
      margin: 0 var(--lp-space-xxs);

      &:last-of-type {
        margin-right: 0;
      }
      a {
        padding: 0;
      }
      &.is-active {
        a {
          &::before {
            bottom: -20px;
          }
        }
      }
    }
    @media (max-width: 1023px) {
      display: none !important;
    }
  }
`;

export const ContentWrap = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding-top: var(--lp-space-md);
  font-family: var(--lp-fonts-body-1);
  padding-bottom: var(--lp-space-xxl);

  @media (max-width: 767px) {
    padding-top: var(--lp-space-sm);
    padding-bottom: var(--lp-space-sm);
  }

  .content-wrap-inner {
    width: 100%;
    margin: 0 auto;

    @media (max-width: 767px) {
      width: calc(100% + 24px);
      max-width: calc(100% + 24px);
      margin-right: var(--lp-space-sm);

      &.with-gutter {
        width: 100%;
        max-width: 100%;
        margin-right: 0;
      }
    }

    &.lp-full {
      max-width: 100%;
    }

    .payment-sidebar {
      max-width: 260px;
      padding-right: var(--lp-space-lg);
      white-space: nowrap;

      @media (max-width: 767px) {
        display: none;
      }
      @media screen and (min-width: 768px) and (max-width: 1199px) {
        max-width: 230px;
      }

      &-inner {
        max-width: 170px;

        @media (min-width: 767px) {
          max-width: 180px;
        }

        button {
          &:active {
            &::before {
              display: none;
            }
          }
        }
      }

      .sub-title {
        font-size: 14px;
        padding: var(--lp-space-xxxs);
        margin-bottom: var(--lp-space-xxxs);
      }
      .lp-button-transaction {
        font-size: 14px;
        color: #555c7c;
        text-align: left;
        font-weight: 500;
        justify-content: flex-start;
        padding: var(--lp-space-xxxs);
        background-color: #fff;
        margin-bottom: var(--lp-space-xxxs);

        &.is-selected {
          font-weight: 900;
          background-color: #f8f8fb;
        }
      }
    }
    .payment-content-area {
      width: 100%;
      min-width: 0;
    }

    h2 {
      color: rgb(var(--lp-colors-neutral-600));

      @media (max-width: 767px) {
        &.page-title {
          font-size: var(--lp-font-size-heading-lg);
        }
      }
      @media (max-width: 991px) {
        &.page-title.text-muted {
          font-size: var(--lp-font-size-heading-xxs) !important;
        }
      }
    }

    .merchant-controls {
      display: flex;
      align-items: center;
      color: rgb(85, 92, 114);
      justify-content: space-between;

      @media (max-width: 767px) {
        padding-right: var(--lp-space-sm);

        .lp-flexContainer {
          width: 100%;
          padding-right: 0 !important;
        }

        &.pr-0 {
          padding-right: 0 !important;
        }
      }

      .lp-flex.order-1.sm-ml-3 {
        margin-right: var(--lp-space-xs) !important;
      }

      .fw-medium {
        font-weight: 500;
      }

      .lp-flexContainer {
        margin: 0;
        align-items: center;

        @media (max-width: 499px) {
          flex-direction: column !important;
        }

        & > .lp-flex {
          margin: 0;

          & > button {
            margin: 0;
            font-weight: 500;
            min-width: inherit;
            white-space: nowrap;
            margin-left: var(--lp-space-xxs);

            svg {
              margin-left: 0;
              margin-right: var(--lp-space-xxs);
            }

            @media (max-width: 490px) {
              &:only-child {
                margin-left: 0;
              }
            }
          }

          @media (max-width: 499px) {
            width: 100%;

            &.order-1 {
              order: 1 !important;
              margin-left: 0 !important;
            }

            &.order-2 {
              margin-left: 0 !important;
              margin-right: 0 !important;
            }
          }

          @media (max-width: 767px) {
            &.order-1 {
              order: 1;
              margin-bottom: var(--lp-space-xs);
            }
            &.order-2 {
              order: 2;
              margin-bottom: var(--lp-space-xs);

              & > button {
                &:first-of-type {
                  margin-left: 0 !important;
                }
              }
            }
          }

          @media screen and (min-width: 768px) and (max-width: 847px) {
            &.order-1 {
              margin-bottom: var(--lp-space-xxxs);
            }
          }
        }

        @media (max-width: 767px) {
          padding-right: var(--lp-space-sm);
        }

        @media (min-width: 992px) {
          flex-wrap: nowrap;
        }
      }

      &.with-border {
        border-bottom: 1px solid rgba(var(--lp-colors-neutral-200), 1);

        .lp-flexContainer {
          margin-bottom: var(--lp-space-xxs);
        }
      }

      @media (max-width: 951px) {
        border-bottom: 0 none;
        -ms-box-orient: vertical;
        box-orient: vertical;
        flex-direction: column;
      }
    }

    .checkbox-column-list {
      padding-top: var(--lp-space-xs);
      padding-bottom: var(--lp-space-sm);
      border-top: 1px solid rgba(var(--lp-colors-neutral-200), 1);

      @media (max-width: 767px) {
        -ms-box-orient: vertical;
        box-orient: vertical;
        flex-direction: column;
      }

      .checkbox-columns {
        width: 100%;
        max-width: 100%;
        column-count: 2;

        @media (max-width: 767px) {
          max-width: 100%;
          column-count: 1;
        }

        label {
          margin-bottom: var(--lp-space-xs) !important;

          &:last-of-type {
            margin-bottom: 0 !important;

            @media (max-width: 767px) {
              margin-bottom: 0 !important;
            }
          }
        }

        @media (max-width: 767px) {
          &:last-of-type {
            margin-top: var(--lp-space-xs) !important;
          }
        }
      }
    }

    .merchant-filters {
      display: flex;
      list-style: none;
      align-items: center;

      @media (max-width: 951px) {
        width: 100%;
        justify-content: flex-start;
        border-bottom: 1px solid rgba(var(--lp-colors-neutral-200), 1);
        margin-bottom: var(--lp-space-md);
      }

      li {
        margin-left: var(--lp-space-sm);

        @media (max-width: 767px) {
          margin-left: 18px;
        }

        &:first-of-type {
          margin-left: 0;
        }

        a,
        button {
          display: block;
          position: relative;
          white-space: nowrap;
          color: rgb(85, 92, 114);
          padding: 0 0 var(--lp-space-xs);
          -webkit-transition: all 0.2s ease 0.25s;
          -moz-transition: all 0.2s ease 0.25s;
          -ms-transition: all 0.2s ease 0.25s;
          -o-transition: all 0.2s ease 0.25s;
          transition: all 0.2s ease 0.25s;

          &:hover {
            color: rgba(85, 92, 114, 0.75);
          }

          &::before {
            left: 0;
            bottom: 0;
            content: '';
            width: 100%;
            height: 2px;
            opacity: 0;
            visibility: hidden;
            border-radius: 4px;
            position: absolute;
            background-color: #555c7c;
            transition: all 0.2s ease 0.25s;
          }

          p {
            font-weight: 500;
          }
        }

        &.is-active {
          a,
          button {
            p {
              font-weight: 900;
            }

            &::before {
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }
    }

    // DataTables

    .rdt_Pagination {
      font-size: 14px;
      font-weight: 400;
      padding-top: 10px;
      padding-left: 0;
      color: rgb(85, 92, 114);
      font-family: var(--lp-fonts-body-1);
      justify-content: flex-start;

      @media (max-width: 767px) {
        padding-right: var(--lp-space-sm);
      }

      div:last-of-type {
        margin-left: auto !important;

        @media (max-width: 767px) {
          margin-left: inherit !important;
        }
      }

      button {
        fill: rgb(85, 92, 114);

        &:disabled {
          fill: rgba(85, 92, 114, 0.25);
        }
      }

      select,
      option {
        font-size: 14px;
        font-weight: 400;
        color: rgb(85, 92, 114);
        font-family: var(--lp-fonts-body-1) !important;
      }
    }

    .rdt_Table {
      font-size: 14px;
      line-height: 1.5;
      letter-spacing: 0.15px;
      font-family: var(--lp-fonts-body-1);

      .rdt_TableCell {
        font-size: 14px;
      }

      .rdt_TableHead {
        font-weight: 700;
        margin-bottom: var(--lp-space-xxxs);

        .rdt_TableHeadRow {
          min-height: 32px;
          border-bottom: 0px none;
          background-color: #f8f8fb;
          border-bottom: 0px none;
          border-radius: var(--lp-space-tiny);

          .rdt_TableCol {
            // cursor: pointer;
            color: #555c7c;
            font-size: 14px;

            .rdt_TableCol_Sortable {
              span {
                top: 1px;
                padding: 0;
                font-size: 0;
                visibility: hidden;
                position: relative;
                margin-left: var(--lp-space-tiny) / 2;
                width: 18px;
                height: 18px;
                display: flex;
                align-items: center;
                justify-content: center;
                visibility: visible;

                &::before {
                  width: 18px;
                  height: 18px;
                  content: '';
                  position: absolute;
                  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23555C7C'%3E%3Cpath d='M7.293 10.707l4 4a1 1 0 001.414 0l4-4a1 1 0 00-1.414-1.414L12 12.586 8.707 9.293a1 1 0 00-1.414 1.414z' fill-rule='evenodd' clip-rule='evenodd'%3E%3C/path%3E%3C/svg%3E");
                  background-size: 18px 18px;
                  background-position: center;
                  background-repeat: no-repeat;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  transform: rotate(180deg);
                }
              }
            }
          }
        }
      }

      .rdt_TableBody {
        .rdt_TableRow {
          border-radius: 6px;
          transition: width 0.6s ease;

          &:hover {
            background-color: #f0f2f9;
          }
          &:focus,
          &:active {
            background-color: #fff;
            -webkit-box-shadow: 0 0 0 2px rgba(121, 69, 224, 0.05), inset 0 0 0 1px rgba(121, 69, 224, 1);
            box-shadow: 0 0 0 2px rgba(121, 69, 224, 0.05), inset 0 0 0 1px rgba(121, 69, 224, 1);
          }
        }
      }
    }
  }

  .filter-dropdown {
    top: 40px;
    right: 0px;
    width: 100%;
    z-index: 3;
    position: absolute;
    background-color: #fff;
    display: flex;
    align-items: stretch;
    -ms-box-orient: vertical;
    box-orient: vertical;
    flex-direction: column;
    border-radius: var(--lp-space-xxxs);
    box-shadow: var(--lp-shadows-medium);

    &.w-328 {
      min-width: 328px;
      max-width: 328px;
    }
    &.w-449 {
      min-width: 449px;
      max-width: 449px;

      @media (max-width: 767px) {
        min-width: 328px;
        max-width: 328px;
      }
    }
    &.w-656 {
      min-width: 656px;
      max-width: 656px;

      @media (max-width: 767px) {
        min-width: 328px;
        max-width: 328px;
      }
    }

    .filter-body {
      flex-grow: 1;
      padding: 0 24px;
      -ms-box-orient: vertical;
      box-orient: vertical;
      flex-direction: column;
      // overflow-y: auto;
      // overflow-x: hidden;
      // max-height: calc(100vh - 250px);

      fieldset {
        margin: 0 -24px;
        width: calc(100% + 48px);

        label {
          width: 100%;
          margin: 0px !important;
        }

        .checkbox-items {
          width: 100%;
          display: flex;
          -ms-box-orient: vertical;
          box-orient: vertical;
          flex-direction: column;
          padding: var(--lp-space-sm);
          border-bottom: 1px solid #f0f2f9;

          &:first-of-type,
          &:only-child {
            padding-top: 5px;
          }

          &:last-of-type,
          &:last-of-type,
          &:only-child {
            border-bottom: 0 none;
          }

          label {
            display: flex;
            align-items: center;
          }

          &-details {
            display: flex;
            -ms-box-orient: vertical;
            box-orient: vertical;
            flex-direction: column;
            padding-top: var(--lp-space-sm);

            .MuiFormLabel-filled {
              top: -24px !important;
            }
            .w-24 {
              width: 24px;
              max-width: 24px;
            }
            .w-24,
            .w-40,
            .w-60px,
            .w-120px {
              flex-shrink: 0;
            }
            .w-40 {
              width: 40px;
              max-width: 40px;
              margin: 0 var(--lp-space-xs);
            }
            .w-60px {
              width: 62px;
              max-width: 62px;
              margin: 0 var(--lp-space-xs);
            }
            .w-140px {
              margin: 0;
              width: 140px;
              max-width: 140px;
            }

            fieldset {
              width: 100%;
              max-width: 100%;
              margin-left: 0;
              margin-right: 0;
              overflow: inherit;
            }
          }
        }
      }
      span.Mui-Error {
        display: inline-block;
        padding-top: var(--lp-space-xxxs);
        color: rgb(var(--lp-colors-error-600));
      }
    }

    .filter-header,
    .filter-footer,
    .filter-body {
      flex-shrink: 0;
    }

    .filter-footer,
    .filter-header {
      color: #191e33;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .filter-header {
      margin-bottom: 24px !important;
      border-bottom: 1px solid #f0f2f9;
      padding: var(--lp-space-sm) var(--lp-space-sm) var(--lp-space-xs);

      h2 {
        color: #191e33;
      }

      .button-close {
        min-width: inherit;
        margin: 0;
        padding: 0;
        width: 24px;
        height: 24px;

        svg {
          fill: #191e33;
        }
      }

      &.mb-0 {
        margin-bottom: 19px !important;
      }
    }

    .filter-footer {
      border-top: 1px solid #f0f2f9;
      padding: var(--lp-space-sm);

      button {
        min-width: inherit;
        max-width: 70px;
        margin: 0 !important;
      }
    }
  }
  .sidebar-title {
    border-bottom: 1px solid #f0f2f9;
    margin-bottom: var(--lp-space-xs);
    padding-bottom: var(--lp-space-xs);
  }
`;

export const MerchantTable = styled.div`
  width: 100%;
  margin-top: var(--lp-space-sm);

  .rdt_Table {
    div[role='row'] {
      div[data-column-id='2'] {
        max-width: 170px;
      }
      div[data-column-id='4'] {
        max-width: 170px;
      }
      div[data-column-id='3'] {
        min-width: 60px;
        max-width: 60px;
      }

      @media (max-width: 767px) {
        div[data-column-id='1'] {
          min-width: 140px;
        }
        div[data-column-id='2'] {
          min-width: 120px;
          max-width: 120px;
        }
      }
    }
  }
`;

export const PaymentsTable = styled.div`
  width: 100%;
  margin-top: var(--lp-space-sm);

  .price {
    opacity: 0.5;
  }

  .rdt_Table {
    div[role='row'] {
      div[data-column-id='1'] {
        min-width: 100px;
        max-width: 100px;
        text-align: right;
        justify-content: flex-end;

        @media (max-width: 767px) {
          min-width: 85px;
          max-width: 85px;
        }
      }

      div[data-column-id='2'] {
        min-width: 70px;
        max-width: 70px;
        color: rgb(var(--lp-colors-neutral-400));
      }

      div[data-column-id='3'] {
        min-width: 120px;
        max-width: 120px;
      }

      div[data-column-id='4'] {
        min-width: 160px;
        max-width: 160px;
      }

      div[data-column-id='6'] {
        min-width: 120px;
        max-width: 120px;
      }

      div[data-column-id='8'] {
        max-width: 170px;
      }
    }
  }
`;

export const TransactionTable = styled.div`
  width: 100%;
  margin-top: var(--lp-space-sm);

  .price {
    opacity: 0.5;
  }

  .rdt_Table {
    div[role='row'] {
      div[data-column-id='1'] {
        min-width: 100px;
        max-width: 100px;
        text-align: right;
        justify-content: flex-end;

        @media (max-width: 767px) {
          min-width: 85px;
          max-width: 85px;
        }
      }

      div[data-column-id='2'] {
        min-width: 70px;
        max-width: 70px;
      }

      div[data-column-id='6'],
      div[data-column-id='7'] {
        max-width: 200px;
      }
    }
  }
`;

export const DefaultAnnouncement = styled.div`
  > div {
    color: #555d7b;
    border: 1px solid #b3b8cb;
    background-color: #f8f8fb !important;
    padding: var(--lp-space-xs);
    border-radius: calc(var(--lp-space-xs) / 4);
    box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.08);

    p {
      color: #555d7b;
      font-size: var(--lp-space-xxs);
      line-height: var(--lp-space-xs);
    }
    div {
      > {
        .lp-fill-current {
          color: #f8f8fb;
          stroke: #555d7b;
        }
      }
    }
  }
  &.align-left {
    > div {
      -webkit-box-pack: flex-start;
      -ms-flex-pack: flex-start;
      -webkit-justify-content: flex-start;
      justify-content: flex-start;
    }

    p {
      align-items: center;
      -webkit-box-pack: flex-start;
      -ms-flex-pack: flex-start;
      -webkit-justify-content: flex-start;
      justify-content: flex-start;

      p {
        margin-right: 0;
      }
    }
  }

  &.align-center {
    > div {
      div {
        align-items: center !important;
        justify-content: flex-start !important;

        @media (max-width: 767px) {
          align-items: flex-start !important;
        }
      }
    }

    p,
    .lp-mt-px {
      margin-top: 0 !important;
      margin-right: 0 !important;
      margin-left: 10px !important;
    }
  }
`;

export const MerchantsData = styled.div`
  margin: 0;
  width: 100%;
  padding-top: var(--lp-space-md);
  // padding-bottom: var(--lp-space-md);
  // border-bottom: 1px solid rgba(var(--lp-colors-neutral-200), 1);

  &.bt-1 {
    border-top: 1px solid rgba(var(--lp-colors-neutral-200), 1);
  }

  &.last,
  &:last-of-type {
    padding-bottom: 0;
    border-bottom: 0 none;
  }

  .lp-half {
    width: 100%;
    max-width: 50%;
    padding-bottom: var(--lp-space-md);
    border-bottom: 1px solid rgba(var(--lp-colors-neutral-200), 1);

    @media (max-width: 767px) {
      width: 100%;
      max-width: 100%;
      -ms-box-orient: vertical;
      box-orient: vertical;
      flex-direction: column;
    }
    &.last {
      padding-bottom: 0;
      border-bottom: 0 none;
    }
  }
  .two-column-block {
    .two-column {
      &:first-of-type,
      &:first-of-type {
        padding-right: var(--lp-space-md);
        @media (max-width: 767px) {
          padding-right: 0;
          margin-bottom: var(--lp-space-xs);
        }
      }
      &:last-of-type,
      &:last-of-type {
        padding-left: var(--lp-space-md);
        @media (max-width: 767px) {
          padding-left: 0;
        }
      }
      &:only-child {
        padding-left: 0;
      }
    }
  }

  .two-column {
    display: flex;
    width: 100%;
    max-width: 50%;
    list-style: none;
    -ms-box-orient: vertical;
    box-orient: vertical;
    flex-direction: column;

    @media (max-width: 767px) {
      width: 100%;
      max-width: 100%;
    }

    li {
      width: 100%;
      max-width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: var(--lp-space-xs);

      &:last-of-type,
      &:last-of-type {
        margin-bottom: 0;
      }

      @media (max-width: 767px) {
        width: 100%;
        max-width: 100%;
      }

      p {
        width: 100%;
        max-width: 50%;
        padding-right: var(--lp-space-xxxs);
        overflow-wrap: break-word;
        word-break: break-word;

        &:last-of-type,
        &:last-of-type {
          text-align: right;
          padding-right: 0;
          padding-left: var(--lp-space-xxxs);
        }
      }
    }

    &.has-border {
      li {
        padding: 8px 0;
        margin-bottom: -1px;
        border-top: 1px solid rgba(var(--lp-colors-neutral-200), 1);
        border-bottom: 1px solid rgba(var(--lp-colors-neutral-200), 1);

        &.no-style {
          border-top: 1px solid #fff;
          border-bottom: 1px solid #fff;
        }
      }
    }
  }
`;

export const WellWrap = styled.div`
  width: 100%;
  margin: 0 auto;
  border-radius: 10px;
  background-color: #f0f2f9;
  padding: var(--lp-space-sm);
  font-family: var(--lp-fonts-body-1);
  margin-bottom: var(--lp-space-md);

  @media (max-width: 767px) {
    padding: var(--lp-space-xs);
    margin-bottom: var(--lp-space-sm);
  }

  &.inline {
    width: auto;
    display: inline-flex;
    padding: var(--lp-space-xxs) var(--lp-space-xs);

    @media (max-width: 767px) {
      width: 100%;
    }

    .labelled-value {
      > div {
        span {
          &:last-of-type {
            line-height: 32px;
          }
        }
      }
      &:last-of-type {
        > div {
          span {
            &:last-of-type {
              color: rgba(var(--lp-colors-primary), 1);
            }
          }
        }
      }
    }
  }

  h2 {
    color: rgb(var(--lp-colors-neutral-700));
  }
  p {
    margin-top: var(--lp-space-xxxs);
    color: rgb(var(--lp-colors-neutral-800));
  }

  .lp-flexContainer {
    margin: 0;

    > div {
      margin: 0;
    }
  }
`;

export const MerchantPaymentsTable = styled.div`
  width: 100%;
  margin-top: var(--lp-space-sm);
`;

export const VirtualTable = styled.div`
  width: 100%;
  margin-top: var(--lp-space-sm);

  // TODO
  .rdt_Table {
    div[role='row'] {
      div[data-column-id='1'] {
        min-width: 40px;
        max-width: 40px;
        text-align: left;
        padding-right: 0;
        padding-left: 1px;
        justify-content: flex-start;

        display: none;

        @media (max-width: 767px) {
          display: flex !important;
          left: 0;
          top: auto;
          position: sticky;
          z-index: 1;
        }
      }

      .rdt_TableCol[data-column-id='9'] {
        @media (max-width: 767px) {
          background-color: #f8f8fb;
        }
      }

      div[data-column-id='9'] {
        min-width: 50px;
        max-width: 50px;

        @media (max-width: 767px) {
          display: none;
        }
      }
    }
  }
`;

export const PayoutsTable = styled.div`
  width: 100%;
  margin-top: var(--lp-space-sm);

  // TODO
  .download-button {
    border: 0 none;
    background-color: transparent;
    color: rgba(var(--lp-colors-primary), 1);

    &.disabled {
      pointer-events: none !important;
      color: rgb(var(--lp-colors-neutral-400));
    }
  }
`;

export const PayoutsBreakdownTable = styled.div`
  width: 100%;
  margin-top: var(--lp-space-sm);

  // TODO
  .download-button {
    border: 0 none;
    background-color: transparent;
    color: rgba(var(--lp-colors-primary), 1);

    &.disabled {
      pointer-events: none !important;
      color: rgb(var(--lp-colors-neutral-400));
    }
  }
`;

export const PayoutSummaryTable = styled.div`
  width: 100%;

  .summary-table {
    width: 100%;
    thead {
      th {
        background-color: #f8f8fb;

        &[data-column-id='1'] {
          border-radius: 4px 0 0 4px;
        }
        &[data-column-id='2'] {
          border-radius: 0;
        }
        &[data-column-id='3'] {
          border-radius: 0 4px 4px 0;
        }
      }
    }

    tbody {
      td {
        border-bottom: 1px solid #f0f2f9;
      }
    }

    thead,
    tbody,
    tfoot {
      th,
      td {
        padding: 8px;
        font-size: 14px;
        color: rgb(var(--lp-colors-neutral-600));

        &[data-column-id='1'] {
          min-width: 145px;
          max-width: 145px;
          text-align: left;

          @media (max-width: 991px) {
            min-width: 125px;
            max-width: 125px;
          }
        }
        &[data-column-id='2'],
        &[data-column-id='3'] {
          min-width: 65px;
          max-width: 65px;
          text-align: right;

          @media (max-width: 991px) {
            min-width: 100px;
            max-width: 100px;
          }
        }
        @media (max-width: 991px) {
          &[data-column-id='2'] {
            display: none;
          }
        }
      }
    }
  }
`;
