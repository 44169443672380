/** @jsxImportSource @emotion/react */

import { InstantFilterInput, LocalDateFilterInput } from 'api/graphql/generated/graphql';
import { DateTime } from 'luxon';

import { Icon, Text } from '@limepayments/cosmic';

export type DateFilterValue =
  | {
      option: 'is_in_the_last';
      unit: 'days' | 'months';
      period: string;
    }
  | {
      option: 'is_between';
      dates: string[];
    }
  | {
      option: 'is_equal_to' | 'is_after' | 'is_before';
      date: string;
    };

export const defaultDateFilter: DateFilterValue = {
  option: 'is_in_the_last',
  unit: 'days',
  period: '',
};

export const toInstantFilterInput = (dateFilter: DateFilterValue): InstantFilterInput | null => {
  const { option } = dateFilter;

  if (option === 'is_in_the_last') {
    const now = DateTime.now();

    return {
      gte: now
        .minus({ [dateFilter.unit]: parseInt(dateFilter.period) })
        .startOf('day')
        .toISO(),
    };
  } else if (option === 'is_between') {
    const start = DateTime.fromISO(dateFilter.dates[0]);
    const end = DateTime.fromISO(dateFilter.dates[1]);

    return { gte: start.startOf('day').toISO(), lte: end.endOf('day').toISO() };
  } else if (option === 'is_equal_to') {
    const date = DateTime.fromISO(dateFilter.date);

    return { gte: date.startOf('day').toISO(), lte: date.endOf('day').toISO() };
  } else if (option === 'is_after') {
    const date = DateTime.fromISO(dateFilter.date);

    return { gt: date.endOf('day').toISO() };
  } else if (option === 'is_before') {
    const date = DateTime.fromISO(dateFilter.date);

    return { lt: date.startOf('day').toISO() };
  }

  return null;
};

export const toLocalDateFilterInput = (dateFilter: DateFilterValue): LocalDateFilterInput | null => {
  const { option } = dateFilter;

  if (option === 'is_in_the_last') {
    const now = DateTime.now();

    return {
      gte: now
        .minus({ [dateFilter.unit]: parseInt(dateFilter.period) })
        .startOf('day')
        .toISODate(),
    };
  } else if (option === 'is_between') {
    const start = DateTime.fromISO(dateFilter.dates[0]);
    const end = DateTime.fromISO(dateFilter.dates[1]);

    return { gte: start.startOf('day').toISODate(), lte: end.endOf('day').toISODate() };
  } else if (option === 'is_equal_to') {
    const date = DateTime.fromISO(dateFilter.date);

    return { eq: date.startOf('day').toISODate() };
  } else if (option === 'is_after') {
    const date = DateTime.fromISO(dateFilter.date);

    return { gt: date.endOf('day').toISODate() };
  } else if (option === 'is_before') {
    const date = DateTime.fromISO(dateFilter.date);

    return { lt: date.startOf('day').toISODate() };
  }

  return null;
};

export type DateFilterProps = {
  value: DateFilterValue | null;
  onChange(value: DateFilterValue): void;
};

export function DateFilter({ onChange, ...props }: DateFilterProps) {
  const value = props.value ?? defaultDateFilter;

  return (
    <>
      <div className="lp-w-full mb-16">
        <div className="selectdropdown">
          <Icon name="ChevronDown" className="arrow-down" />
          <select
            className="form-control"
            value={value.option}
            onChange={({ target: { value: option } }) =>
              onChange(
                option === 'is_in_the_last'
                  ? { option, unit: 'days', period: '' }
                  : option === 'is_between'
                  ? { option, dates: ['', ''] }
                  : ({ option, date: '' } as DateFilterValue),
              )
            }
          >
            <option value="is_in_the_last">is in the last</option>
            <option value="is_equal_to">is equal to</option>
            <option value="is_between">is between</option>
            <option value="is_after">is after</option>
            <option value="is_before">is before</option>
          </select>
        </div>
      </div>
      <div className="lp-w-full lp-flex lp-align-center mb-0">
        <div className="w-24">
          <Icon name="Return" className="text-primary" />
        </div>
        {value.option === 'is_in_the_last' ? (
          <>
            <div className="w-40">
              <input
                type="text"
                className="form-control"
                value={value.period}
                onChange={({ target: { value: period } }) => onChange({ ...value, period })}
              />
            </div>
            <div className="selectdropdown">
              <Icon name="ChevronDown" className="arrow-down" />
              <select
                className="form-control"
                value={value.unit}
                onChange={({ target: { value: unit } }) => onChange({ ...value, unit } as DateFilterValue)}
              >
                <option value="days">days</option>
                <option value="months">months</option>
              </select>
            </div>
          </>
        ) : value.option === 'is_between' ? (
          <div className="lp-w-full ml-16px">
            <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 4 }}>
              <input
                type="date"
                className="form-control"
                value={value.dates[0]}
                onChange={({ target: { value: date } }) => onChange({ ...value, dates: [date, value.dates[1]] })}
              />
              <Text tagName="span" variant="body-3">
                and
              </Text>
              <input
                type="date"
                className="form-control"
                value={value.dates[1]}
                onChange={({ target: { value: date } }) => onChange({ ...value, dates: [value.dates[0], date] })}
              />
            </div>
          </div>
        ) : (
          <div className="lp-w-full ml-16px">
            <input
              type="date"
              className="form-control"
              value={value.date}
              onChange={({ target: { value: date } }) => onChange({ ...value, date })}
            />
          </div>
        )}
      </div>
    </>
  );
}
