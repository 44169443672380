import { DateTime } from 'luxon';
import { MerchantDefaultRoutes } from 'pages/merchant/constants';
import { DashboardRoutesList } from 'pages/merchant/dashboard/constants';
import { PayoutRoutesList, toPayoutStatusObj } from 'pages/merchant/dashboard/payout/constants';
import { TableStyleSettings, TableThemeSettings } from 'pages/platform/constants';
import { useMemo, useState } from 'react';
import DataTable, { SortOrder, TableColumn, createTheme } from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/merchant/hooks';
import { toCurrency } from 'utils/currency';
import { getMerchantBaseUrl } from 'utils/getBaseUrl';

import { Icon, Spinner } from '@limepayments/cosmic';

import * as s from '../../../../../../assets/styles/Platforms.styles';
import PayoutCSVDownloadModal from '../../partials/PayoutCSVDownloadModal';
import { PayoutsQueryEdges } from '../types';
import EmptyComponent from './EmptyComponent';
import EmptyPayoutFilterComponent from './EmptyPayoutFilterComponent';
import ErrorComponent from './ErrorComponent';
import StatusTag from './StatusTagComponent';

// createTheme creates a new theme named solarized that overrides the build in dark theme
createTheme('polarized', TableThemeSettings, 'dark');

interface Props {
  payoutList: Array<PayoutsQueryEdges[number]>;
  fetchListLoader: boolean;
  totalRows: number;
  handlePerRowsChange: (newPerPage: number, page: number) => void;
  handlePageChange: (page: number) => void;
  limit: number;
  activePage: number;
  isFilterRequest: boolean;
  handleSort: (selectedColumn: TableColumn<PayoutsQueryEdges[number]>, sortDirection: SortOrder) => void;
  isErrorRequest?: boolean;
  toggleFilter: () => void;
}

function PayoutDataTable({
  payoutList,
  totalRows,
  limit,
  fetchListLoader,
  handlePageChange,
  handlePerRowsChange,
  activePage,
  handleSort,
  isFilterRequest,
  isErrorRequest,
  toggleFilter,
}: Props) {
  const navigate = useNavigate();

  const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
  const [downloadPayoutId, setDownloadPayoutId] = useState<string>();

  const columns: TableColumn<PayoutsQueryEdges[number]>[] = useMemo(
    () => [
      {
        name: 'Amount',
        selector: (row: PayoutsQueryEdges[number]) =>
          toCurrency(row.node.amount.minorCurrencyUnits, row.node.amount.currency),
        width: '100px',
        right: true,
      },
      {
        name: '',
        selector: (row: PayoutsQueryEdges[number]) => row.node.amount.currency,
        width: '70px',
        style: {
          color: 'rgb(var(--lp-colors-neutral-400))',
        },
      },
      {
        name: 'Payout ID',
        selector: (row: PayoutsQueryEdges[number]) => row.node.payoutId,
        minWidth: '250px',
      },
      {
        name: 'Created on',
        selector: (row: PayoutsQueryEdges[number]) =>
          row.node.createdAt ? `${DateTime.fromISO(row.node.createdAt).toFormat('dd/LL/yyyy, HH:mm')}` : '',
        sortable: true,
        sortField: 'createdAt',
        minWidth: '170px',
      },
      {
        name: 'Available on',
        selector: (row: PayoutsQueryEdges[number]) =>
          row.node.settlementDate ? `${DateTime.fromISO(row.node.settlementDate).toFormat('dd/LL/yyyy, HH:mm')}` : '',
        minWidth: '170px',
      },
      {
        name: 'Status',
        cell: (row: PayoutsQueryEdges[number]) => {
          const status = toPayoutStatusObj(row.node.status);
          return (
            <StatusTag
              label={status.label}
              variant={status.varient}
              tooltipDirection="left"
              tooltipText={status.tooltipText}
            />
          );
        },
        width: '110px',
      },
      {
        name: '',
        cell: (row: PayoutsQueryEdges[number]) => {
          return (
            <>
              <button
                className="download-button"
                onClick={() => {
                  setDownloadPayoutId(row.node.payoutId);
                  setShowDownloadModal(true);
                }}
              >
                <Icon name="Download" />
              </button>
              <PayoutCSVDownloadModal
                modalToggler={setShowDownloadModal}
                isOpen={showDownloadModal && downloadPayoutId === row.node.payoutId}
                payoutId={row.node.payoutId}
                payoutAmount={row.node.amount.minorCurrencyUnits}
                currency={row.node.amount.currency}
                availableOn={row.node.settlementDate || ''}
              />
            </>
          );
        },
        width: '50px',
      },
    ],
    [downloadPayoutId, showDownloadModal],
  );

  const { merchantName } = useAppSelector((state) => ({
    merchantName: state.config.merchantName,
  }));

  return (
    <>
      <s.PayoutsTable>
        <DataTable
          pagination
          responsive
          columns={columns}
          data={payoutList}
          customStyles={TableStyleSettings}
          theme="polarized"
          className=""
          progressPending={fetchListLoader}
          progressComponent={
            <div className="transaction-empty">
              <div className="transaction-empty-inner">
                <Spinner variant="simple" isVisible label="Loading..." />
              </div>
            </div>
          }
          noDataComponent={
            isErrorRequest ? (
              <ErrorComponent />
            ) : isFilterRequest && payoutList.length < 1 ? (
              <EmptyPayoutFilterComponent toggleFilter={toggleFilter} />
            ) : (
              <EmptyComponent />
            )
          }
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          paginationPerPage={limit}
          paginationDefaultPage={activePage}
          onRowClicked={(row) =>
            navigate(
              `${getMerchantBaseUrl(merchantName)}/${MerchantDefaultRoutes.DASHBOARD_ROUTE}/${
                DashboardRoutesList.PAYOUT_ROUTE
              }/${PayoutRoutesList.PAYOUT_DETAILS}?payoutId=${row.node.payoutId}`,
            )
          }
          onSort={handleSort}
          sortServer
          persistTableHead
          defaultSortFieldId={4}
          defaultSortAsc={false}
          paginationRowsPerPageOptions={[20, 40, 60, 80, 100]}
          pointerOnHover={true}
        />
      </s.PayoutsTable>
    </>
  );
}

export default PayoutDataTable;
