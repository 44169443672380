import { SmallCheckboxes } from 'pages/common/filters';
import { useState } from 'react';

import { Button, Checkbox, CheckboxGroup, Heading, Icon } from '@limepayments/cosmic';

interface Props {
  toggleFilter: () => void;
}

function FilterComponent({ toggleFilter }: Props) {
  const [checkedAmount, setCheckedAmount] = useState(false);
  const [checkedOrderId, setCheckedOrderId] = useState(false);
  const [checkedCustomer, setCheckedCustomer] = useState(false);
  const [checkedDescription, setCheckedDescription] = useState(false);
  const [checkedRequestedDate, setCheckedRequestedDate] = useState(false);
  const [checkedStatus, setCheckedStatus] = useState(false);

  const handleAmountChange = () => {
    setCheckedAmount(!checkedAmount);
  };

  const handleOrderIdChange = () => {
    setCheckedOrderId(!checkedOrderId);
  };

  const handleCustomerChange = () => {
    setCheckedCustomer(!checkedCustomer);
  };

  const handleDescriptionChange = () => {
    setCheckedDescription(!checkedDescription);
  };

  const handleRequestedDate = () => {
    setCheckedRequestedDate(!checkedRequestedDate);
  };

  const handleStatusChange = () => {
    setCheckedStatus(!checkedStatus);
  };

  return (
    <div className="lp-flex lp-flex-column">
      <div className="filter-header mb-0">
        <Heading alignment="left" tagName="h2" variant="xs" className="mb-0">
          Filters
        </Heading>
        <Button
          type="button"
          onClick={() => toggleFilter()}
          size="small"
          variant="ghost"
          className="button-close m-0 p-0"
        >
          <Icon name="Cross" className="m-0 p-0" />
        </Button>
      </div>
      <div className="filter-body">
        <CheckboxGroup>
          <div className="checkbox-items">
            <Checkbox id="testid" label="Amount" variant="body-2" onChange={handleAmountChange} />
            {checkedAmount && (
              <div className="checkbox-items-details">
                <div className="lp-w-full mb-16">
                  <div className="selectdropdown">
                    <Icon name="ChevronDown" className="arrow-down" />
                    <select className="form-control" data-testid="payment-filter-date-select">
                      <option value="">please select</option>
                      <option value="is_equal_to">is equal to</option>
                      <option value="is_between">is between</option>
                      <option value="is_after">is greater than</option>
                      <option value="is_before">is less than</option>
                    </select>
                  </div>
                </div>
                <div className="lp-w-full lp-flex lp-align-center mb-0">
                  <div className="w-24">
                    <Icon name="Return" className="text-primary" />
                  </div>
                  <div className="w-60pxs ml-16px">
                    <input type="text" data-testid="payment-filter-date-value" className="form-control radius-0" />
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="checkbox-items">
            <Checkbox id="testid2" label="Order ID" variant="body-2" onChange={handleOrderIdChange} />
            {checkedOrderId && (
              <div className="checkbox-items-details">
                <div className="lp-w-full filter-search position-relative mb-0">
                  <input
                    type="text"
                    data-testid="payment-filter-order-id-input"
                    className="form-control border-bottom"
                    placeholder="Search"
                  />
                  <Button type="button" size="small" variant="ghost" className="button-search">
                    <Icon name="Search" className="m-0 p-0 text-primary" />
                  </Button>
                </div>
              </div>
            )}
          </div>

          <div className="checkbox-items">
            <Checkbox id="testid3" label="Customer" variant="body-2" onChange={handleCustomerChange} />
            {checkedCustomer && (
              <div className="checkbox-items-details">
                <div className="lp-w-full filter-search position-relative mb-0">
                  <input
                    type="text"
                    data-testid="payment-filter-order-id-input"
                    className="form-control border-bottom"
                    placeholder="Search"
                  />
                  <Button type="button" size="small" variant="ghost" className="button-search">
                    <Icon name="Search" className="m-0 p-0 text-primary" />
                  </Button>
                </div>
              </div>
            )}
          </div>

          <div className="checkbox-items">
            <Checkbox id="testid4" label="Description" variant="body-2" onChange={handleDescriptionChange} />
            {checkedDescription && (
              <div className="checkbox-items-details">
                <div className="lp-w-full filter-search position-relative mb-0">
                  <input
                    type="text"
                    data-testid="payment-filter-order-id-input"
                    className="form-control border-bottom"
                    placeholder="Search"
                  />
                  <Button type="button" size="small" variant="ghost" className="button-search">
                    <Icon name="Search" className="m-0 p-0 text-primary" />
                  </Button>
                </div>
              </div>
            )}
          </div>

          <div className="checkbox-items">
            <Checkbox id="testid5" label="Requested date" variant="body-2" onChange={handleRequestedDate} />
            {checkedRequestedDate && (
              <div className="checkbox-items-details">
                <div className="lp-w-full mb-16">
                  <div className="selectdropdown">
                    <Icon name="ChevronDown" className="arrow-down" />
                    <select className="form-control" data-testid="payment-filter-date-select">
                      <option value="">please select</option>
                      <option value="is_in_the_last">is in the last</option>
                      <option value="is_equal_to">is equal to</option>
                      <option value="is_between">is between</option>
                      <option value="is_after">is after</option>
                      <option value="is_before">is before</option>
                    </select>
                  </div>
                </div>
                <div className="lp-w-full lp-flex lp-align-center mb-0">
                  <div className="w-24">
                    <Icon name="Return" className="text-primary" />
                  </div>
                  <div className="w-40">
                    <input type="text" data-testid="payment-filter-date-value" className="form-control" />
                  </div>
                  <div className="selectdropdown">
                    <Icon name="ChevronDown" className="arrow-down" />
                    <select className="form-control" data-testid="payment-filter-date-filter-value">
                      <option value="">please select</option>
                      <option value="days">days</option>
                      <option value="month">months</option>
                    </select>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="checkbox-items">
            <Checkbox id="testid6" label="Status" variant="body-2" onChange={handleStatusChange} />
            {checkedStatus && (
              <div className="checkbox-items-details">
                <SmallCheckboxes>
                  <CheckboxGroup>
                    <Checkbox id="statusId" label="Created" variant="body-3" />
                    <Checkbox id="statusId2" label="Cancelled" variant="body-3" />
                  </CheckboxGroup>
                </SmallCheckboxes>
              </div>
            )}
          </div>
        </CheckboxGroup>
      </div>
      <div className="filter-footer">
        <Button className="lp-w-full" onClick={function noRefCheck() {}} size="medium" variant="reversed">
          Clear
        </Button>
        <Button className="lp-w-full" onClick={function noRefCheck() {}} size="medium" variant="primary">
          Save
        </Button>
      </div>
    </div>
  );
}

export default FilterComponent;
