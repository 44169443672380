import { GetMerchantBalancesResponse } from 'api/merchant/dashboard/settlement.types';
import * as s from 'assets/styles/Platforms.styles';
import DashboardRoutesList from 'pages/merchant/dashboard/constants';
import { useMemo } from 'react';
import { SortOrder, TableColumn } from 'react-data-table-component';
import { useAppSelector } from 'redux/merchant/hooks';
import { toCurrency } from 'utils/currency';

import { Button, FlexContainer, Heading, Icon, Text } from '@limepayments/cosmic';

import PayoutSidebar from '../../partials/PayoutSidebar';
import { PayoutsQueryEdges, getFilterCount } from '../types';
// Import Static UIs
import FilterComponent from './FilterComponent';
import PayoutDataTable from './PayoutDataTable';

export interface Props {
  balance: GetMerchantBalancesResponse;
  payoutList: PayoutsQueryEdges;
  fetchListLoader: boolean;
  totalRows: number;
  handlePerRowsChange: (newPerPage: number, page: number) => void;
  handlePageChange: (page: number) => void;
  limit: number;
  toggleFilter: () => void;
  filterPopupOpen: boolean;
  activePage: number;
  handleSort: (selectedColumn: TableColumn<PayoutsQueryEdges[number]>, sortDirection: SortOrder) => void;
  isFilterRequest?: boolean;
  isErrorRequest?: boolean;
}

function PayoutBalancePage({
  balance,
  payoutList,
  totalRows,
  limit,
  fetchListLoader,
  handlePageChange,
  handlePerRowsChange,
  toggleFilter,
  filterPopupOpen,
  activePage,
  handleSort,
  isFilterRequest,
  isErrorRequest,
}: Props) {
  const { payoutFilterObj } = useAppSelector((state) => ({
    payoutFilterObj: state.payout.payoutFilterObj,
  }));

  const filterCount = useMemo(() => getFilterCount(payoutFilterObj), [payoutFilterObj]);

  return (
    <div className="content-wrap-inner lp-full m-0">
      <div className="lp-w-full lp-flex">
        <PayoutSidebar activeTab={DashboardRoutesList.PAYOUT_ROUTE} />

        <div className="payment-content-area">
          <div className="lp-w-full lp-flex lp-justify-between sm-lp-flex-col bb-1 pb-16">
            <Heading alignment="left" tagName="h2" variant="sm">
              Overview
            </Heading>
          </div>
          <s.MerchantsData className="mb-32 bb-1 pb-16">
            <Heading alignment="left" tagName="h2" variant="sm" className="mb-16">
              Balance
            </Heading>

            <div className="lp-flex-column two-column-block">
              <ul className="two-column">
                <li>
                  <Text tagName="p" variant="body-3" className="text-dark-50">
                    Available to pay out to your bank
                  </Text>
                  <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0" isEmphasised>
                    {balance.available === 0 ? '-' : toCurrency(balance.available, balance.currency)}
                  </Text>
                </li>
                <li>
                  <Text tagName="p" variant="body-3" className="text-dark-50">
                    Pending amount on the way to bank
                  </Text>
                  <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0" isEmphasised>
                    {balance.pending === 0 ? '-' : toCurrency(balance.pending, balance.currency)}
                  </Text>
                </li>
                <li>
                  <Text tagName="p" variant="body-3" className="text-dark-50">
                    In transit amount
                  </Text>
                  <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0" isEmphasised>
                    {balance.inTransit === 0 ? '-' : toCurrency(balance.inTransit, balance.currency)}
                  </Text>
                </li>
                <li className="bt-1">
                  <Text tagName="p" variant="body-3" className="text-dark-50" isEmphasised>
                    Total
                  </Text>
                  <Text tagName="p" variant="body-3" className="text-purple m-0 mt-0" isEmphasised>
                    {balance.inTransit === 0 && balance.pending === 0 && balance.available === 0
                      ? '-'
                      : toCurrency(balance.inTransit + balance.pending + balance.available, balance.currency)}
                  </Text>
                </li>
              </ul>
            </div>
          </s.MerchantsData>
          <div className="lp-w-full lp-flex lp-justify-between sm:flex-col">
            <Heading alignment="left" tagName="h2" variant="sm">
              Payouts
            </Heading>
            <div className="merchant-controls sm-mt-16 sm:lp-full sm:pr-0">
              <FlexContainer classNames="lp-flex m-0">
                <div className="lp-flex order-2 position-relative m-0 mb-0">
                  <Button
                    className="lp-w-full"
                    size="small"
                    variant="reversed"
                    onClick={() => toggleFilter()}
                    disabled={payoutList.length < 1 && filterCount === 0}
                  >
                    <Icon name="Filter" className="ml-0 mr-16" />
                    Filter ({filterCount})
                  </Button>

                  {/* Filter Dropdown */}
                  {filterPopupOpen && <FilterComponent modalToggler={toggleFilter} />}
                </div>
              </FlexContainer>
            </div>
          </div>

          <PayoutDataTable
            payoutList={payoutList}
            fetchListLoader={fetchListLoader}
            totalRows={totalRows}
            handlePerRowsChange={handlePerRowsChange}
            handlePageChange={handlePageChange}
            limit={limit}
            activePage={activePage}
            handleSort={handleSort}
            isFilterRequest={isFilterRequest || filterCount > 0}
            isErrorRequest={isErrorRequest}
            toggleFilter={toggleFilter}
          />
        </div>
      </div>
    </div>
  );
}

export default PayoutBalancePage;
