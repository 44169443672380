/** @jsxImportSource @emotion/react */

import { searchPayoutsQueryDocument } from 'api/graphql';
import { useMemo, useState } from 'react';
import { useQuery } from 'urql';

import { SearchFilter, SearchFilterOption, SearchFilterProps } from './SearchFilter';

export type PayoutFilterProps = {
  merchantIds: string | string[];
  value: SearchFilterOption | null;
  onChange(value: SearchFilterOption | null): void;
};

export function PayoutFilter({ merchantIds, value, onChange, ...props }: PayoutFilterProps) {
  const [query, setQuery] = useState('');

  const pause = query.length < 3;

  const [{ fetching, data, error }] = useQuery({
    query: searchPayoutsQueryDocument,
    variables: { merchantIds, query },
    pause,
  });

  const options = useMemo<SearchFilterProps['options']>(() => {
    if (pause || !data?.search.payouts) return [];

    return data.search.payouts.map(({ payoutId }) => ({
      value: payoutId,
      label: payoutId,
    }));
  }, [data?.search.payouts, pause]);

  return (
    <SearchFilter
      value={value}
      options={options}
      placeholder="Search payout ID"
      loading={fetching}
      error={error?.message}
      onChange={onChange}
      onInputChange={setQuery}
    />
  );
}
